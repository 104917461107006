import React, { useContext } from "react";
import {
  Alert,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import "./style.css";
import { UserRepository } from "../../Repositories/UserRepository";
import { Link, useNavigate } from "react-router-dom";
import { StringValues } from "../../Strings";
import { UserContext } from "../../Providers/UserProvider";
import { View } from "../../Components/View";
import { useFormik } from "formik";
import * as Yup from "yup";

const userRepository = new UserRepository();

export const Login = () => {
  const navigate = useNavigate();

  const [, setUser] = useContext(UserContext);

  const [isError, setError] = React.useState<boolean>(false);

  const showErrorMessage = () => {
    setError(true);
    setTimeout(() => setError(false), 5000);
  };

  const onLogin = (email: string, password: string) => {
    userRepository
      .login(email, password)
      .then((response) => {
        if (response.succes) {
          setUser(response.data.user);
          navigate("/");
        } else showErrorMessage();
      })
      .catch(() => {
        showErrorMessage();
      });
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required("Campo Obligatorio"),
    password: Yup.string().required("Campo Obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      onLogin(values.email, values.password);
    },
  });

  console.log("Formik", formik);

  const hasErrors = Object.keys(formik.errors).length > 0;

  return (
    <View>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={6}>
          <Paper className="contenedor" variant="outlined">
            <form onSubmit={formik.handleSubmit}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <h2 style={{ padding: 0, margin: 0 }}>
                    {StringValues.LoginTitle}
                  </h2>
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12} spacing={2}>
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="p"
                    style={{ margin: "0 10px" }}
                  >
                    <p>
                      Si ha olvidado su contraseña debe contactar con  <Link to="/contacto">el
                      investigador</Link>
                    </p>
                    <p>
                      Si aun no dispone de cuenta, puede registrarse en el
                      siguiente <Link to="/signin">enlace</Link> (es necesario
                      disponer de contraseña administrada por el investigador).
                    </p>
                  </Typography>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Correo Electronico"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    fullWidth
                  />
                </Grid>
                <Grid item xl={6} md={6} sm={12} xs={12}>
                  <TextField
                    required
                    id="password"
                    name="password"
                    type={"password"}
                    label="Contraseña"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    fullWidth
                  />
                </Grid>
                <Grid item xl={5} md={5} sm={11} xs={11}>
                  <Button
                    fullWidth
                    variant="outlined"
                    disabled={hasErrors}
                    type="submit"
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
          {isError && (
            <Alert
              style={{ marginTop: "30px" }}
              variant="outlined"
              severity="error"
            >
              {StringValues.LoginError}
            </Alert>
          )}
        </Grid>
      </Grid>
    </View>
  );
};
