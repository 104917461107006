import React, { FC, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Chip,
  TableFooter,
} from "@mui/material";
import { View } from "../../Components/View";
import RubricaQuestions from "../../RubricaQuestions.json";
import styles from "./styles.module.css";
import { useParams } from "react-router-dom";
import { RotatorioRepository } from "../../Repositories/RotatorioRepository";
import { TestWithReview } from "../../Models/Test";
import { Student } from "../../Models/Student";
import { StudentRepository } from "../../Repositories/StudentRepository";
import { LoadingPage } from "../../Components/Loading/LoadingPage";
import { EvaluacionFinalRepository } from "../../Repositories/EvaluacionFinalRepository";
import { StudentProfileCard } from "../Students/StudentProfileCard";
import { RubricaRepository } from "../../Repositories/RubricaRepository";
import { RubricaRow } from "../../Components/Rubrica";

const rubricaRepository = new RubricaRepository();
const evaluacionFinalRepository = new EvaluacionFinalRepository();
const rotatorioRepository = new RotatorioRepository();
const studentRepository = new StudentRepository();

export const ReviewTest: FC = () => {
  const params = useParams();
  const [ready, setReady] = React.useState(false);
  const [evaluation, setEvaluation] = React.useState<null | TestWithReview>(
    null
  );
  const [student, setStudent] = React.useState<null | Student>(null);

  const { reviewId, testType } = params;
  const repository =
    testType === "rotatorio" ? rotatorioRepository : evaluacionFinalRepository;
  React.useEffect(() => {
    if (!reviewId) return;
    repository
      .getReview(reviewId)
      .then((reviewResponse) => {
        setEvaluation(reviewResponse.data);
        return studentRepository.getById(reviewResponse.data.studentId);
      })
      .then((studentResponse) => setStudent(studentResponse.data))
      .then(() => setReady(true));
  }, [params]);

  if (!ready) return <LoadingPage />;
  if (!student || !evaluation) return <div>Error en la descarga de datos</div>;
  return (
    <EvaluationRender
      evaluation={evaluation}
      student={student}
      ucs={RubricaQuestions.tutores}
    />
  );
};

export const EvaluationRender: React.FC<{
  evaluation: TestWithReview;
  student: Student;
  ucs: { id: string; title: string; rows: any[] }[];
}> = (props) => {
  return (
    <View>
      <Container style={{ padding: "10px 0" }}>
        <Stack spacing={2}>
          <Typography
            gutterBottom
            variant="h5"
            component="span"
            style={{ textAlign: "left" }}
          >
            RESULTADOS DE EVALUACIÓN ECOEnf-SM
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="span"
            style={{ textAlign: "left" }}
          >
            Aplicación de la Escala de Evaluación de Competencias Profesionales
            de los Residentes de Enfermería de Salud Mental (ECOEnf-SM)
          </Typography>

          <Paper className={styles.container} variant="outlined">
            <StudentProfileCard userId={props.student.id || ""} />
          </Paper>

          <Paper className={styles.container} variant="outlined">
            <TestReviewTable evaluation={props.evaluation} />
          </Paper>

          <RequiredFails fails={props.evaluation.review.requiredFails} />
        </Stack>
      </Container>
    </View>
  );
};

const RequiredFails: FC<{ fails: string[] }> = ({ fails }) => {
  const [rubricas, setRubricas] = useState<RubricaRow[]>([]);
  const [ucTitleMap, setUcTitleMap] = useState<{ [x: string]: string }>({});

  React.useEffect(() => {
    rubricaRepository.getAll().then((rubricas) => {
      const rubricasByUc = rubricas.data;
      const rubricasAllQuestions = Object.values(rubricasByUc.data).reduce<
        RubricaRow[]
      >((acc, current) => {
        acc = [...acc, ...current.rows];
        return acc;
      }, []);
      const ucTitleMap = Object.values(rubricasByUc.data).reduce(
        (acc, current) => {
          const key = current.id;
          const value = current.title;
          return { ...acc, [key]: value };
        },
        {}
      );
      setUcTitleMap(ucTitleMap);
      setRubricas(rubricasAllQuestions);
    });
  }, []);

  const failsByUcs = fails.reduce<{
    [uc: string]: {
      ucTitle: string;
      failures: { failKey: string; failText: string }[];
    };
  }>((acc, current) => {
    const uc = current.split("-")[0];
    const failKey: string = current;
    const failText =
      rubricas.find((rubrica) => rubrica.id === failKey)?.title || "";
    const previousFailures = acc[uc] ? acc[uc].failures : [];
    const failures = [...previousFailures, { failKey, failText }];
    return { ...acc, [uc]: { ucTitle: ucTitleMap[uc], failures } };
  }, {});

  if (Object.values(failsByUcs).length === 0) return <></>;

  return (
    <Paper className={styles.container} variant="outlined">
      <TableContainer>
        <Typography
          gutterBottom
          variant="h6"
          component="span"
          style={{ textAlign: "left", paddingBottom: "10px" }}
        >
          Criterios Imprescindibles no aprobados:
        </Typography>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Unidad de Competencia.</TableCell>
              <TableCell>Unidad Requerida Fallida</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(failsByUcs).map((failsByUc) => (
              <TableRow hover>
                <TableCell component="th" scope="row">
                  {failsByUc.ucTitle}
                </TableCell>
                <TableCell component="th" scope="row">
                  {failsByUc.failures.map((f) => f.failText).join(", ")}
                </TableCell>
                <TableCell component="th" scope="row">
                  {failsByUc.failures.map((f) => f.failText).join(", ")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const TestReviewTable: FC<{ evaluation: TestWithReview }> = (props) => {
  const [ucTitleMap, setUcTitleMap] = useState<{ [x: string]: string }>({});

  React.useEffect(() => {
    rubricaRepository.getAll().then((rubricas) => {
      const rubricasByUc = rubricas.data;
      const ucTitleMap = Object.values(rubricasByUc.data).reduce(
        (acc, current) => {
          const key = current.id;
          const value = current.title;
          return { ...acc, [key]: value };
        },
        {}
      );
      setUcTitleMap(ucTitleMap);
    });
  }, []);
  return (
    <>
      <TableContainer>
        <Typography
          gutterBottom
          variant="h6"
          component="span"
          style={{ textAlign: "left", paddingBottom: "10px" }}
        >
          Resumen De Notas De Las Unidades De Competencia:
        </Typography>

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Unidad de Competencia</TableCell>
              <TableCell>Min. Aciertos</TableCell>
              <TableCell>Aciertos</TableCell>
              <TableCell>Nota</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.evaluation.review.reviewByUC.map((uc) => (
              <TableRow key={uc.uc} color="danger" hover>
                <TableCell component="th" scope="row">
                  {ucTitleMap[uc.uc]}
                </TableCell>
                <TableCell component="th" scope="row">
                  {uc.min}
                </TableCell>
                <TableCell component="th" scope="row">
                  <p
                    style={{
                      color: uc.responseSuccess < uc.min ? "red" : "initial",
                    }}
                  >
                    {uc.responseSuccess} /{" "}
                    {uc.responseSuccess + uc.fails.length}{" "}
                  </p>
                </TableCell>
                <TableCell component="th" scope="row">
                  {Math.round(uc.ucMark * 100) / 100}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="subtitle1">NOTA FINAL</Typography>
                  <Chip
                    variant="outlined"
                    color={props.evaluation.review.pass ? "success" : "error"}
                    label={props.evaluation.review.pass ? "APTO" : "NO APTO"}
                  />
                  {!props.evaluation.review.pass && (
                    <Typography
                      gutterBottom
                      variant="caption"
                      component="span"
                      style={{ textAlign: "left" }}
                    >
                      Existen unidades de competencia suspensas por no alcanzar:
                      la nota mínima (5/10), el número mínimo de criterios de
                      evaluación o por criterios imprescindibles no superados.
                      Se recomienda mejorar en esas temáticas. El cálculo de la
                      nota media prevalece sobre la calificación de NO APTO y
                      siempre será decisión final la del tutor/a.
                    </Typography>
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};
