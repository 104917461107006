import { Button, Paper, Stack, Typography } from '@mui/material';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { StringValues } from '../../Strings';
import { View } from '../../Components/View';
import { URLS } from '../../Urls';
import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

export const TeacherHome = () => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <View>
      <Paper className={styles.contenedor} variant="outlined">
        <Typography
          gutterBottom
          variant="h3"
          component="span"
          style={{ textAlign: 'left' }}
        >
          {StringValues.TeacherTitle}
        </Typography>

        <Typography
          gutterBottom
          variant="body1"
          component="span"
          className={styles.text}
          style={{ textAlign: 'left' }}
        >
          <ReactMarkdown>{StringValues.TeacherText}</ReactMarkdown>
        </Typography>

        <Stack
          direction={'column'}
          spacing={1}
          alignItems="center"
          justifyContent="center"
          className={styles.buttonContainer}
        >
          <Button
            className={styles.button}
            variant="outlined"
            onClick={() => navigate(URLS.students)}
          >
            {StringValues.BotonGestionAlumnos}
          </Button>
          <Button
            className={styles.button}
            variant="outlined"
            onClick={() => navigate(URLS.testStart)}
          >
            {StringValues.BotonEvalua}
          </Button>
          <Button
            className={styles.button}
            variant="outlined"
            onClick={() => navigate(URLS.reviewStart)}
          >
            {StringValues.BotonEvaluaciones}
          </Button>
        </Stack>
      </Paper>
    </View>
  );
};
