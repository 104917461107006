import { useState } from 'react';
import { TestWithReview } from '../../../Models/Test';
import { RotatorioAndEvaluacionRepository } from '../../../Repositories/rotatorioAndEvaluacionRepository';
import { TestBodyRow } from '../BodyRows/TestBodyRow';
import { TestTableFilter } from '../Filters/TestFilter';
import { TestHeadRow } from '../HeadRows/TestHeadRow';
import { OnUpdateTable, PaginatedTable } from '../paginatedTable';

const rotatorioAndEvaluacionRepository = new RotatorioAndEvaluacionRepository();

export const ProfileTestTable: React.FC = (props) => {
  const [reviews, setReviews] = useState<TestWithReview[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const onUpdateQuery: OnUpdateTable = ({ page, rowsPerPage, filter }) => {
    setLoading(true);
    rotatorioAndEvaluacionRepository
      .getAll({ page, size: rowsPerPage, filter })
      .then((response) => {
        setReviews(response.data);
        setCount(response.count);
        setLoading(false);
      });
  };

  console.log('loading');

  return (
    <>
      <PaginatedTable
        title="Mis Evaluaciones"
        filterRow={TestTableFilter}
        loading={loading}
        onUpdate={onUpdateQuery}
        headRow={TestHeadRow}
        bodyRow={TestBodyRow}
        count={count}
        data={reviews}
      ></PaginatedTable>
    </>
  );
};
