import * as React from 'react';
import styles from './styles.module.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { UserContext } from '../../Providers/UserProvider';
import { StringValues } from '../../Strings';
import { Profile } from './Profile';
import { useNavigate } from 'react-router-dom';

const pages: any[] = [];

type TopBarProps = {
  handleOpenNavMenu: () => void;
};

export const TopBar: React.FC<TopBarProps> = (props) => {
  const navigate = useNavigate();
  const [, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [userInfo, setUser] = React.useContext(UserContext);

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="sticky" variant="outlined" sx={{ zIndex: 99999 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => props.handleOpenNavMenu()}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="subtitle1"
            className={styles.topBarTitle}
            noWrap
            component="a"
            onClick={() => navigate('/')}
            sx={{ flexGrow: 1, display: { xs: 'flex' } }}
          >
            {StringValues.TopBarTitle}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {userInfo.user && (
            <Profile
              anchorElUser={anchorElUser}
              handleCloseNavMenu={handleCloseNavMenu}
              handleCloseUserMenu={handleCloseUserMenu}
              handleOpenUserMenu={handleOpenUserMenu}
              user={userInfo.user}
              setUser={setUser}
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
