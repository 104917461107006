import { Student } from './Student';

export enum RotatorioType {
  Csm = 'CentroSaludMental',
  Uhb = 'UnidadHospitalizacionBreve',
}

export enum TestType {
  EvaluacionAnual = 'EvaluacionAnual',
  RotatorioUHB = 'RotatorioUHB',
  RotatorioCSM = 'RotatorioCSM',
}

export type StudentData = {
  id: string;
  studentYear: string;
  academicYear: string;
  rotatorioType?: RotatorioType;
  rotatorioCaption?: string;
};

export type RubricaResponses = { [ucx: string]: { [ucxy: string]: number } };

export type TestWithReview = {
  academicYear: string;
  id: string;
  createdAt: Date;
  responses: RubricaResponses;
  review: {
    pass: boolean;
    mark: number;
    fails: string[];
    requiredFails: string[];
    reviewByUC: {
      fails: string[];
      min: number;
      requiredFails: [];
      responseSuccess: number;
      uc: string;
      ucMark: number;
      ucPass: boolean;
    }[];
  };
  studentId: string;
  studentYear: number;
  teacherId: string;
  type: string;
  rotatorioType: string;
  rotatorioCaption: string;
  student?: Student;
};

export type YearReview = {
  rotatorioAvg: {
    uc1: number;
    uc2: number;
    uc3: number;
    uc4: number;
    uc8: number;
  };
  evaluacionReviewAvg: {
    uc5: number;
    uc6: number;
    uc7: number;
  };
  allAvg: {
    uc1: number;
    uc2: number;
    uc3: number;
    uc4: number;
    uc8: number;
    uc5: number;
    uc6: number;
    uc7: number;
  };
  uce: number;
  uct: number;
  mark: number;
  pass: boolean;
};
