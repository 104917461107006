import { User } from "../Models/User"
import { ApiRepository } from "./ApiRepository"

export class UserRepository extends ApiRepository {
  protected baseURl = `${this.getUrl()}/users`;

  login(email: string, password: string) {
    const body = JSON.stringify({ email, password });
    const url = `${this.baseURl}/login`;
    const requestInit: RequestInit = {
      method: 'POST',
      body,
    };
    return this.request<{ token: string; user: User }>(url, requestInit);
  }

  logout() {
    const url = `${this.baseURl}/login`;
    const requestInit: RequestInit = {
      method: 'DELETE',
    };
    return this.request<null>(url, requestInit);
  }

  getAll() {
    const url = `${this.baseURl}`;
    const requestInit: RequestInit = {};
    return this.request<User[]>(url, requestInit);
  }

  async getProfile() {
    const url = `${this.baseURl}/me`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<User>(url, requestInit);
  }

  addUser(user: User) {
    const url = `${this.baseURl}`;
    const body = JSON.stringify(user);
    const requestInit: RequestInit = {
      method: 'POST',
      body,
    };
    return this.request<User>(url, requestInit);
  }

  addTeacher(user: {
    name: string;
    surname: string;
    email: string;
    password: string;
    teacherPassword: string;
    school: string;
    region: string;
  }) {
    const url = `${this.baseURl}/teacher`;
    const body = JSON.stringify(user);
    const requestInit: RequestInit = {
      method: 'POST',
      body,
    };
    return this.request<User>(url, requestInit);
  }

  async delete(email: string) {
    const url = `${this.baseURl}`;
    const body = JSON.stringify({ email });
    const requestInit: RequestInit = {
      method: 'DELETE',
      body,
    };

    return this.request<User>(url, requestInit);
  }

  async getByEmail(email: string) {
    const url = `${this.baseURl}/${email}`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<User>(url, requestInit);
  }

  async updateUser(email: string, user: Partial<User>) {
    const url = `${this.baseURl}/${email}`;
    const body = JSON.stringify(user);
    const requestInit: RequestInit = {
      method: 'PUT',
      body,
    };
    return this.request<User>(url, requestInit);
  }
}
