import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Drawer, ListSubheader, useMediaQuery } from '@mui/material';
import { StringValues } from '../../Strings';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../Urls';

const ListElements = {
  informacion: {
    MenuInfo: URLS.info,
    MenuEscala: URLS.escala,
    MenuHistoria: URLS.historia,
    MenuActualidad: URLS.actualidad,
  },
  'Tutores Eir': {
    MenuTutores: URLS.teacherHome,
  },
  MenuDocumentacion: URLS.documentation,
  MenuColaboradores: URLS.collaborators,
  MenuContacto: URLS.contact,
};

type ListProps = {
  handleClose: () => void;
};

const PublicList: React.FC<ListProps> = (props) => {
  const navigate = useNavigate();

  const ListGroup = (props: {
    tree: { [k: string]: string };
    title: string;
  }) => {
    return (
      <List
        subheader={<ListSubheader>{props.title.toUpperCase()}</ListSubheader>}
      >
        <Divider />

        {Object.keys(props.tree as any).map((text: string) => (
          <ListItem
            button
            key={text}
            onClick={() => navigate(props.tree[text])}
          >
            <ListItemText secondary={(StringValues as any)[text] as string} />
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={() => props.handleClose()}
      onKeyDown={() => props.handleClose()}
    >
      <List sx={{ '&': { marginTop: '10px' } }}>
        {Object.keys(ListElements).map((title: string) =>
          typeof (ListElements as any)[title] === 'object' ? (
            <ListGroup tree={(ListElements as any)[title]} title={title} />
          ) : (
            <ListItem button key={title}>
              <ListItemText
                primary={(StringValues as any)[title]}
                onClick={() => navigate((ListElements as any)[title] as string)}
              />
            </ListItem>
          )
        )}
      </List>
    </Box>
  );
};

type DrawableProps = {
  isopen: boolean;
  setOpen: (o: boolean) => void;
};

export const MobileDrawable: React.FC<DrawableProps> = (props) => {
  return (
    <SwipeableDrawer
      anchor={'left'}
      open={props.isopen}
      onClose={() => props.setOpen(false)}
      onOpen={() => props.setOpen(true)}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, top: '56px' },
      }}
    >
      <PublicList handleClose={() => props.setOpen(false)} />
    </SwipeableDrawer>
  );
};

const drawerWidth = 250;

export const DesktopDrawable: React.FC<DrawableProps> = (props) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, top: '56px' },
      }}
    >
      <PublicList handleClose={() => props.setOpen(false)} />
    </Drawer>
  );
};

export const Drawable: React.FC<DrawableProps> = (props) => {
  const isDesktop = useMediaQuery('(min-width:800px)');

  return isDesktop ? (
    <DesktopDrawable isopen={props.isopen} setOpen={props.setOpen} />
  ) : (
    <MobileDrawable isopen={props.isopen} setOpen={props.setOpen} />
  );
};