import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';

export const StudentHeadRow: FC = () => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell> Nombre </TableCell>
      <TableCell> Sexo </TableCell>
      <TableCell> Fecha de Creacion </TableCell>
      <TableCell> Fecha nacimiento </TableCell>
      <TableCell> Año de Residencia </TableCell>
    </TableRow>
  );
};
