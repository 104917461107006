import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { View } from '../../Components/View';
import { URLS } from '../../Urls';
import { FC, useEffect } from 'react';

type AdminCardProps = {
  title: string;
  body: string;
  links: { title: string; url: string }[];
};

const AdminCard: FC<AdminCardProps> = (props) => {
  const navigate = useNavigate();
  const { title, body, links } = props;

  return (
    <Card variant={'outlined'}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="span">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {body}
        </Typography>
      </CardContent>
      <CardActions className={styles.cardActions}>
        {links.map((link) => (
          <Button
            size="small"
            color="primary"
            onClick={() => {
              navigate(link.url);
            }}
          >
            {link.title}
          </Button>
        ))}
      </CardActions>
    </Card>
  );
};

export const Admin = () => {
  useEffect(() => {}, []);

  return (
    <View>
      <div className={styles.container}>
        <AdminCard
          title="Usuarios"
          body="Administración de usuarios"
          links={[
            { title: 'Tutores', url: URLS.users },
            { title: 'Alumnos', url: URLS.users },
          ]}
        />
        <AdminCard
          title="Markdown"
          body="Test markdown test"
          links={[{ title: 'app', url: URLS.markdown }]}
        />
        <AdminCard
          title="Data"
          body="Descargate la informacion de la aplicación"
          links={[
            { title: 'rotatorios y evaluaciones', url: URLS.dataTests },
            { title: 'evaluaciones finales', url: URLS.markdown },
          ]}
        />
      </div>
    </View>
  );
};
