import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { View } from '../../Components/View';
import { UserRepository } from '../../Repositories/UserRepository';
import { Provincias, UnidadDocentes } from '../../Strings';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../Urls';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';


const repository = new UserRepository();

export const SignIn: React.FC = (props) => {
  const navigate = useNavigate();

  const [successMsg, setSuccessMsg] = React.useState<null | string>(null);
  const [errorMsg, setErrorMsg] = React.useState<null | string>(null);

  const showErrorMessage = (msg: string) => {
    setErrorMsg(msg);
    setTimeout(() => setErrorMsg(null), 15000);
  };

  const [school, setSchool] = React.useState<string | null>(null);
  const [region, setRegion] = React.useState<string | null>(null);

  const SignupSchema = Yup.object().shape({
    name: Yup.string().required('Campo Obligatorio'),
    surname: Yup.string().required('Campo Obligatorio'),
    email: Yup.string().email('Email Invalido').required('Campo Obligatorio'),
    teacherPassword: Yup.string()
      .min(6, 'Contraseña invalida, minimo 6 caracteres')
      .required('Campo Obligatorio'),
    password: Yup.string()
      .min(6, 'Contraseña invalida, minimo 6 caracteres')
      .required('Campo Obligatorio'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      teacherPassword: '',
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      try {
        console.log('Hasta aqui hemos llegado, todo bien', school, region);
        if (!school) throw new Error('Seleccione una unidad docente.');
        if (!region) throw new Error('Seleccione una provincia.');

        const response = await repository.addTeacher({
          name: values.name,
          surname: values.surname,
          email: values.email,
          password: values.password,
          teacherPassword: values.teacherPassword,
          school,
          region,
        });

        if (!response.succes) {
          throw new Error((response.data as any).error);
        }
        setSuccessMsg('Registro exitoso');
        setTimeout(() => navigate(URLS.login), 3000);
      } catch (error) {
        showErrorMessage((error as Error).message);
      }
    },
  });

  const hasErrors = Object.keys(formik.errors).length > 0;

  console.log('formik', formik.errors);

  return (
    <View>
      <Paper className="contenedor" variant="outlined">
        <h1 style={{ textAlign: 'left' }}>Registro</h1>

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3} alignContent="center" alignItems="center">
            <Card
              variant="outlined"
              style={{ padding: '20px', margin: '0 20%' }}
            >
              <Stack spacing={3} alignContent="center" alignItems="center">
                <TextField
                  style={{ width: '350px' }}
                  required
                  type="password"
                  id="outlined-password-input"
                  label="Código de Tutor"
                  name="teacherPassword"
                  error={formik.errors.teacherPassword ? true : false}
                  helperText={
                    formik.errors.teacherPassword
                      ? formik.errors.teacherPassword
                      : ''
                  }
                  onChange={formik.handleChange}
                  value={formik.values.teacherPassword}
                />
                <Typography gutterBottom variant="body2" component="p">
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{
                    `El "código tutor", es un código proporcionado con anterioridad
                    por el investigador.\\
                    Si usted es tutor/a de una UDM
                    de Salud Mental encargado de evaluar al residente y no tiene
                    este código, póngase en contacto con el [investigador](https://ecoenfsm.es/contacto).`
                }</ReactMarkdown>
                </Typography>
              </Stack>
            </Card>

            <Stack direction="row" spacing={5}>
              <TextField
                style={{ width: '350px' }}
                required
                type="email"
                id="outlined-password-input"
                label="Correo Electronico"
                name="email"
                error={formik.errors.email ? true : false}
                helperText={formik.errors.email ? formik.errors.email : ''}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <TextField
                style={{ width: '350px' }}
                required
                type="password"
                id="outlined-password-input"
                label="Password"
                name="password"
                error={formik.errors.password ? true : false}
                helperText={
                  formik.errors.password ? formik.errors.password : ''
                }
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            </Stack>

            <Stack direction="row" spacing={5}>
              <TextField
                style={{ width: '350px' }}
                required
                type="text"
                id="outlined-password-input"
                label="Nombre"
                name="name"
                error={formik.errors.name ? true : false}
                helperText={formik.errors.name ? formik.errors.name : ''}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <TextField
                style={{ width: '350px' }}
                required
                type="text"
                id="outlined-password-input"
                label="Apellidos"
                name="surname"
                error={formik.errors.surname ? true : false}
                helperText={formik.errors.surname ? formik.errors.surname : ''}
                onChange={formik.handleChange}
                value={formik.values.surname}
              />
            </Stack>

            <Stack direction="row" spacing={5}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={UnidadDocentes}
                sx={{ width: 350 }}
                onChange={(e, value) => setSchool((value as any).unidad)}
                renderInput={(params) => (
                  <TextField {...params} label="Unidad Docente" />
                )}
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={Provincias}
                sx={{ width: 350 }}
                onChange={(e, value) => setRegion((value as any)?.provincia)}
                renderInput={(params) => (
                  <TextField {...params} label="Provincia" />
                )}
              />
            </Stack>

            <Button disabled={hasErrors} type="submit">
              Finalizar el Registro
            </Button>
          </Stack>
        </form>
        {errorMsg && (
          <Alert
            style={{ marginTop: '30px' }}
            variant="outlined"
            severity="error"
          >
            {errorMsg}
          </Alert>
        )}
        {successMsg && (
          <Alert
            style={{ marginTop: '30px' }}
            variant="outlined"
            severity="success"
          >
            {successMsg}
          </Alert>
        )}
      </Paper>
    </View>
  );
};
