import {
  Button,
  Chip,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import './style.css';
import { View } from '../../../Components/View';
import { UserRepository } from '../../../Repositories/UserRepository';
import React from 'react';
import { User } from '../../../Models/User';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../../Urls';

const userRepository = new UserRepository();

const UserRow: React.FC<{
  user: User;
  onDelete: (u: User) => void;
  onEdit: (u: User) => void;
}> = ({ user, onDelete, onEdit }) => {
  return (
    <TableRow
      key={user.name}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {user.email}
      </TableCell>
      <TableCell align="left">{[user.name, user.surname].join(' ')}</TableCell>
      <TableCell align="left">
        {' '}
        <Chip label={user.role} color="primary" variant="outlined" />
      </TableCell>
      <Stack
        direction={'row'}
        alignContent={'end'}
        alignItems={'end'}
        textAlign={'end'}
      >
        <TableCell align="right">
          <Button color="error" onClick={() => onDelete(user)}>
            {' '}
            Borrar
          </Button>
        </TableCell>
        <TableCell align="right">
          <Button onClick={() => onEdit(user)}>Editar</Button>
        </TableCell>
      </Stack>
    </TableRow>
  );
};

export const ListUsers = () => {
  const navigate = useNavigate();

  const [users, setUsers] = React.useState<User[]>([]);

  React.useEffect(() => {
    userRepository.getAll().then((u) => setUsers(u.data));
  }, []);

  const onCreateUser = () => {
    navigate(`${URLS.users}/new`);
  };

  const onDeleteUser = (user: User) => {
    userRepository.delete(user.email);
  };

  const onEditUser = (user: User) => {
    navigate(`${URLS.users}/${user.email}`);
  };

  return (
    <View>
      <Paper
        className="contenedor"
        variant="outlined"
        style={{ position: 'relative' }}
      >
        <Stack spacing={2}>
          <Stack
            direction={'row'}
            alignContent={'space-between'}
            alignItems={'center'}
            justifyContent="space-between"
          >
            <Button
              variant="outlined"
              size="small"
              endIcon={<Add />}
              onClick={onCreateUser}
            >
              Nuevo
            </Button>
          </Stack>
          <Table aria-label="simple table" style={{ marginBottom: '60px' }}>
            <TableHead>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell> User </TableCell>
                <TableCell> Nombre </TableCell>
                <TableCell> Role </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {users.map((u, i) => (
                <UserRow
                  key={i}
                  user={u}
                  onDelete={onDeleteUser}
                  onEdit={onEditUser}
                />
              ))}
            </TableBody>
          </Table>
        </Stack>
      </Paper>
    </View>
  );
};
