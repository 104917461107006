import React from 'react';
import {
  TextField,
  Stack,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from '@mui/material';
import { InputPassword } from '../../../Components/InputPassword';
import { Roles, User } from '../../../Models/User';
import { UserRepository } from '../../../Repositories/UserRepository';
import { View } from '../../../Components/View';
import { useNavigate, useParams } from 'react-router-dom';
import { URLS } from '../../../Urls';

type UserViewProps = {
  user: User;
  onSave: (u: User) => void;
  onCancel: () => void;
};

const repository = new UserRepository();

const UserView: React.FC<UserViewProps> = (props) => {
  const [password, setPassword] = React.useState<string>(
    props.user.password || ''
  );
  const [email, setemail] = React.useState<string>(props.user.email);
  const [name, setName] = React.useState<string>(props.user.name);
  const [surname, setSurname] = React.useState<string>(props.user.surname);
  const [role, setRole] = React.useState<string>(props.user.role);

  const onAddUser = () => {
    const user: User = {
      email,
      password,
      name,
      surname,
      role,
    };
    props.onSave(user);
  };

  return (
    <View>
      <Card style={{ marginTop: '20px', padding: '20px' }} variant="outlined">
        <CardContent>
          <Stack spacing={3}>
            <Typography
              gutterBottom
              variant="h5"
              component="span"
              style={{ textAlign: 'left' }}
            >
              Usuario
            </Typography>
            <Stack direction="row" spacing={1}>
              <TextField
                required
                id="outlined-email-input"
                label="Usuario"
                name="user"
                fullWidth={false}
                style={{ width: '200px' }}
                value={email}
                onChange={(event) => setemail(event.target.value)}
              />
              <Select
                labelId="Role"
                id="role"
                label="role"
                value={role}
                onChange={(event) => setRole(event.target.value)}
                style={{ width: '200px' }}
              >
                <MenuItem value={Roles.Teacher}>Teacher</MenuItem>
                <MenuItem value={Roles.Admin}>Admin</MenuItem>
              </Select>
            </Stack>
            <Stack direction="row" spacing={1}>
              <TextField
                required
                id="outlined-email-input"
                label="Nombre"
                name="name"
                style={{ width: '200px' }}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <TextField
                required
                id="outlined-email-input"
                label="Apellidos"
                name="surname"
                style={{ width: '200px' }}
                value={surname}
                onChange={(event) => setSurname(event.target.value)}
              />
            </Stack>
            <InputPassword password={password} onChangePassword={setPassword} />
          </Stack>
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <Button size="small" color="error" onClick={props.onCancel}>
            Cancelar
          </Button>
          <Button size="small" variant="outlined" onClick={onAddUser}>
            Guardar
          </Button>
        </CardActions>
      </Card>
    </View>
  );
};

export const CreateUser: React.FC = () => {
  const navigate = useNavigate();
  const defaultPassword = Math.random().toString(36).slice(-8);

  const defaultUser: User = {
    name: '',
    surname: '',
    password: defaultPassword,
    role: Roles.Teacher,
    email: '',
  };

  const onSave = (user: User) => {
    repository.addUser(user).then(() => navigate(URLS.home));
  };

  const onCancel = () => {
    navigate(URLS.users);
  };

  return <UserView user={defaultUser} onSave={onSave} onCancel={onCancel} />;
};

export const EditUser: React.FC = () => {
  const navigate = useNavigate();

  const params = useParams();
  const email = params.email;

  const [user, setUser] = React.useState<User | null>(null);

  React.useEffect(() => {
    repository.getByEmail(email!).then((user) => {
      setUser(user.data);
    });
  }, [email]);

  const onEdit = (u: User) => {
    const userEdited: Partial<User> = {};
    if (user === null) return;
    if (u.email !== user.email) userEdited.email = u.email;
    if (u.password && u.password !== user.password) userEdited.password = u.password;
    if (u.name !== user.name) userEdited.name = u.name;
    if (u.surname !== user.surname) userEdited.surname = u.surname;
    if (u.role !== user.role) userEdited.role = u.role;
    repository.updateUser(email!, userEdited).then(() => navigate(URLS.users));
  };

  const onCancel = () => {
    navigate(URLS.users);
  };

  return user ? (
    <UserView user={user} onSave={onEdit} onCancel={onCancel} />
  ) : null;
};
