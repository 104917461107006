export enum Gender {
  Male = 'male',
  Female = 'female'
}

export enum SchoolYear {
  Eir1 = 1,
  Eir2 = 2
}

export type Student = {
  id?: string;
  createdAt?: Date;
  name: string;
  surname: string;
  gender: Gender;
  schoolYear: SchoolYear;
  birthday: Date;
  school?: String;
  teacherId: string;
};