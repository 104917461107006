import { Box, Container, CssBaseline } from '@mui/material';
import React from 'react';
import { CustomBreadcrumb } from '../Breadcrumb';
import { Drawable } from '../Drawable';
import { TopBar } from '../TopBar';
import background from "./background.png";

import styles from './styles.module.css';

const backgroundStyle: React.CSSProperties = {
  backgroundImage: `url(${background}) `,
  backgroundRepeat: 'repeat',
  minHeight: '95vh'
}

export const View: React.FC<{ className?: string }> = (props) => {
  const [drawableOpen, setDrawableOpen] = React.useState(false);

  return (
    <Box sx={{ display: 'static', msFlexDirection: 'column' }}>
      <CssBaseline />
      <TopBar handleOpenNavMenu={() => setDrawableOpen(!drawableOpen)} />
      <Box sx={{ display: 'flex', msFlexDirection: 'row' }} style={backgroundStyle}>
        <Drawable isopen={drawableOpen} setOpen={setDrawableOpen} />
        <Container
          className={props.className || styles.container}
          sx={{ flexGrow: 1, p: 3 }}
        >
          <CustomBreadcrumb />
          {props.children}
        </Container>
      </Box>
    </Box>
  );
};
