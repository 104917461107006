import React from "react";
import { Container, LinearProgress, Stack } from "@mui/material";
import logo from "./ecoenf.png";
import { Box } from "@mui/system";

export const LoadingPage = () => {
  return (
    <Container>
      <Stack spacing={3} alignItems="center">
        <Box sx={{ width: "100%" }}>
          <img src={logo} alt="Logo" width="30%" />
          <LinearProgress />
        </Box>
      </Stack>
    </Container>
  );
};
