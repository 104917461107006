import { Paper, Typography } from "@mui/material";
import styles from "./styles.module.css";
import { View } from "../../../Components/View";
import Editor from "@monaco-editor/react";
import { useEffect, useState } from "react";
import { RotatorioRepository } from "../../../Repositories/RotatorioRepository";

const rotatorioRepository = new RotatorioRepository();

export const AdminDataTests = () => {
  const [editorValues, setEditorValue] = useState("");

  useEffect(() => {
    rotatorioRepository
      .getAll()
      .then((u) => setEditorValue(JSON.stringify(u.data, null, 2)));
  }, []);

  return (
    <View>
      <Typography
        gutterBottom
        variant="h3"
        component="span"
        style={{ textAlign: "left" }}
      >
        Rotatorios y Evaluaciones
      </Typography>
      <Typography
        gutterBottom
        variant="body1"
        component="span"
        className={styles.text}
        style={{ textAlign: "left" }}
      >
      </Typography>

      <Paper className={styles.contenedor} variant="outlined" key="pape">
        <Editor
          height="70vh"
          defaultLanguage="javascript"
          defaultValue={editorValues}
        />
      </Paper>
    </View>
  );
};
