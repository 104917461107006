import HomeIcon from "@mui/icons-material/Home";

export const URLS = {
  home: "/", // done
  login: "/login", // done
  signin: "/signin", // done
  info: "/informacion", // done
  contact: "/contacto",
  tutorial: "/tutorial",
  collaborators: "/colaboradores",
  documentation: "/documentacion",
  actualidad: "/actualidad", // done
  situacion: "/situacion", // done
  historia: "/historia",
  escala: "/escala",
  admin: "/admin", // done
  users: "/admin/users", // done
  markdown: "/admin/markdown", // done
  teacherHome: "/tutores", // done
  profile: "/tutores/perfil", //done
  students: "/tutores/alumnos", // done
  testStart: "/tutores/test", // done
  testRotatorio: "/tutores/test/rotatorio", // done
  testEvaluacion: "/tutores/test/evaluacion-final", //done
  reviewStart: "/tutores/review", // done
  reviewStudent: "/tutores/review/student", // done
  reviewRotatorio: "/tutores/review/rotatorio", // done
  reviewEvaluacionFinal: "/tutores/review/evaluacion-final", // done
  reviewYear: "/tutores/review/year", // done
  dataTeachers: "/admin/data/profesores",
  dataStudents: "/admin/data/alumnos",
  dataTests: "/admin/data/tests",
  dataFinal: "/admin/data/evaluacion-final",
};

export type UrlArchitectureType = {
  title: string;
  url: string;
  icon?: any;
  children: UrlArchitectureType[];
};

export const UrlArchitecture: UrlArchitectureType = {
  title: "Home",
  url: "/",
  icon: HomeIcon,
  children: [
    {
      title: "Login",
      url: URLS.login,
      children: [],
    },
    {
      title: "Contacto",
      url: URLS.contact,
      children: [],
    },
    {
      title: "Colaboradores",
      url: URLS.collaborators,
      children: [],
    },
    {
      title: "Documentacion",
      url: URLS.documentation,
      children: [],
    },
    {
      title: "Registro",
      url: URLS.signin,
      children: [],
    },
    {
      title: "Informacion",
      url: URLS.info,
      children: [],
    },
    {
      title: "Actualidad",
      url: URLS.actualidad,
      children: [],
    },
    {
      title: "Situacion",
      url: URLS.situacion,
      children: [],
    },
    {
      title: "Historia",
      url: URLS.historia,
      children: [],
    },
    {
      title: "Escala",
      url: URLS.escala,
      children: [],
    },
    {
      title: "Admin",
      url: URLS.admin,
      children: [
        {
          title: "Tutores",
          url: URLS.users,
          children: [],
        },
        {
          title: "Markdown",
          url: URLS.markdown,
          children: [],
        },
        {
          title: "Profile",
          url: URLS.profile,
          children: [],
        },
        {
          title: "Data",
          url: URLS.admin,
          children: [
            {
              title: "Tests",
              url: URLS.dataTests,
              children: [],
            },
            {
              title: "Evaluacion-Final",
              url: URLS.dataFinal,
              children: [],
            },
          ],
        },
      ],
    },
    {
      title: "Tutores",
      url: URLS.teacherHome,
      children: [
        {
          title: "Profile",
          url: URLS.profile,
          children: [],
        },
        {
          title: "Alumnos",
          url: URLS.students,
          children: [],
        },
        {
          title: "Evalua",
          url: URLS.testStart,
          children: [
            {
              title: "Rotatorio",
              url: URLS.testRotatorio,
              children: [],
            },
            {
              title: "Evaluacion Final",
              url: URLS.testEvaluacion,
              children: [],
            },
          ],
        },
        {
          title: "Revision",
          url: URLS.reviewStart,
          children: [
            {
              title: "Alumno",
              url: URLS.reviewStudent,
              children: [],
            },
            {
              title: "Rotatorio",
              url: URLS.reviewRotatorio,
              children: [],
            },
            {
              title: "Evaluacion final",
              url: URLS.reviewEvaluacionFinal,
              children: [],
            },
            {
              title: "Evaluación escolar",
              url: URLS.reviewYear,
              children: [],
            },
          ],
        },
      ],
    },
  ],
};

export const ApiDomain = "eresm.reshuhormiguero.club/api";
