import {
  RubricaResponses,
  StudentData,
  TestWithReview,
  YearReview,
} from '../Models/Test';
import { ApiRepository } from './ApiRepository';

type PostBody = {
  type: 'Rotatorio' | 'EvaluacionAnual';
  rotatorioType?: string;
  rotatorioCaption: string;
  studentId: string;
  responses: RubricaResponses;
  studentYear: string;
  academicYear: string;
};
export class EvaluacionFinalRepository extends ApiRepository {
  protected baseURl = `${this.getUrl()}/test`;

  add(student: StudentData, responses: RubricaResponses) {
    const b: PostBody = {
      type: 'EvaluacionAnual',
      rotatorioCaption: '',
      academicYear: student.academicYear,
      studentId: student.id,
      studentYear: student.studentYear,
      responses,
    };
    const body = JSON.stringify(b);
    const url = `${this.baseURl}/${student.id}`;
    const requestInit: RequestInit = {
      method: 'POST',
      body,
    };
    return this.request<{ id: string }>(url, requestInit);
  }

  getReview(id: string) {
    const url = `${this.baseURl}/${id}`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<TestWithReview>(url, requestInit);
  }

  getAll() {
    const url = `${this.baseURl}/evaluacion`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<TestWithReview[]>(url, requestInit);
  }

  getAllFromStudent(studentId: string) {
    const url = `${this.baseURl}/evaluacion/${studentId}`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<TestWithReview[]>(url, requestInit);
  }

  getYearReview(studentId: string) {
    const url = `${this.getUrl()}/review/${studentId}`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<YearReview>(url, requestInit);
  }
}
