import React from 'react'
import { CustomizedAccordions } from '../CustomAccordion'
import { RubricaResponses } from './rubricaResponses';

export type RubricaRow = {
  id: string;
  title: string;
  subtitle?: string;
  values: string[];
};

export type RubricaInfo = {
  id: string;
  title: string;
  columns: string[];
  rows: RubricaRow[];
};

export const Rubrica: React.FC<{
  rubricaInfo: RubricaInfo;
  onResponse: (question: string, response: number) => void;
}> = (props) => {
  const [responses, setResponses] = React.useState<{ [x: number]: boolean }>(
    {}
  );

  const { columns, rows } = props.rubricaInfo;
  const [itemExpanded, onChangeItemExpanded] = React.useState<number>(0);

  const onResponse = (question: number, response: number) => {
    onChangeItemExpanded(itemExpanded + 1);
    props.onResponse(props.rubricaInfo.rows[question].id, response);
    setResponses({ ...responses, [question]: true });
  };

  return (
    <>
      {rows.map((row, index) => {
        let isResponsed = responses[index];
        return (
          <CustomizedAccordions
            key={row.title}
            expanded={index === itemExpanded}
            title={row.title}
            subtitle={row.subtitle}
            isResponsed={isResponsed}
            onChangeExpanded={() => onChangeItemExpanded(index)}
            wrap={
              <RubricaResponses
                head={columns}
                responses={row.values}
                onResponse={(response) => {
                  onResponse(index, response);
                  isResponsed = true;
                }}
              />
            }
          />
        );
      })}
    </>
  );
};
