import React from 'react'
import { Paper, Stack, TextField, Typography } from '@mui/material';
import { View } from '../../Components/View';
import { LoadingPage } from '../../Components/Loading/LoadingPage';
import { UserContext } from '../../Providers/UserProvider';
import { User } from '../../Models/User';
import { RotatorioAndEvaluacionRepository } from '../../Repositories/rotatorioAndEvaluacionRepository';
import { TestWithReview } from '../../Models/Test';
import { ProfileTestTable } from '../../Components/PaginatedTable/Tables/ProfileTestTable';

const reviewRepository = new RotatorioAndEvaluacionRepository();

const MyProfile: React.FC<{ user: User }> = (props) => {
  return (
    <div>
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={5}>
          <TextField
            style={{ width: '500px' }}
            required
            type="text"
            label="Nombre"
            value={props.user.name}
            name="name"
          />
          <TextField
            style={{ width: '500px' }}
            required
            type="text"
            id="outlined-password-input"
            label="Apellidos"
            value={props.user.surname}
            name="surname"
          />
        </Stack>
        <Stack direction={'row'} spacing={5}>
          <TextField
            style={{ width: '500px' }}
            required
            type="text"
            id="outlined-password-input"
            label="Unidad Docente Multidisciplinar"
            value={props.user.role}
            name="school"
          />
          <TextField
            style={{ width: '500px' }}
            required
            type="text"
            id="outlined-password-input"
            label="Correo Electronico"
            value={props.user.email}
            name="email"
          />
        </Stack>
      </Stack>
    </div>
  );
};

export const Profile = () => {
  const [loaded, setLoaded] = React.useState(false);
  const [reviews, setReviews] = React.useState<TestWithReview[] | null>(null);

  const [userInfo] = React.useContext(UserContext);

  React.useEffect(() => {
    reviewRepository.getAll().then((response) => {
      const reviews = response.data;
      setReviews([...reviews]);
      setLoaded(true);
    });
  }, []);

  if (!userInfo.user) return <LoadingPage />;
  if (!loaded) return <LoadingPage />;
  if (!reviews) return <LoadingPage />;

  return (
    <View>
      <Stack spacing={2}>
        <Paper style={{ padding: '10px' }} variant="outlined">
          <Typography
            gutterBottom
            variant="h6"
            component="span"
            style={{ textAlign: 'left' }}
          >
            Mis datos
          </Typography>
          <MyProfile user={userInfo.user} />
        </Paper>
        <Typography
          gutterBottom
          variant="h5"
          component="span"
          style={{ textAlign: 'left' }}
        ></Typography>
        <Paper style={{ padding: '10px' }} variant="outlined">
          <ProfileTestTable />
        </Paper>
      </Stack>
    </View>
  );
};

