import { Paper } from "@mui/material";
import styles from "./styles.module.css";
import { View } from "../../Components/View";
import { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import uno from "./1.png";
import dos from "./2.png";
import tres from "./3.png";
import cuatro from "./4.png";
import cinco from "./5.png";
import seis from "./6.png";
import siete from "./7.png";

export const Tutorial = () => {

  useEffect(() => {}, []);

  return (
    <View>
      <Paper className={styles.contenedor} variant="outlined">
        <Carousel showArrows autoPlay width="75%">
          <div>
            <img src={uno} alt="Logo" width="50%" />
            <p className="legend">Legend 1</p>
          </div>
          <div>
            <img src={dos} alt="Logo" width="50%" />
            <p className="legend">Legend 2</p>
          </div>
          <div>
            <img src={tres} alt="Logo" width="50%" />
            <p className="legend">Legend 3</p>
          </div>
          <div>
            <img src={cuatro} alt="Logo" width="50%" />
            <p className="legend">Legend 3</p>
          </div>
          <div>
            <img src={cinco} alt="Logo" width="50%" />
            <p className="legend">Legend 3</p>
          </div>
          <div>
            <img src={seis} alt="Logo" width="50%" />
            <p className="legend">Legend 3</p>
          </div>
          <div>
            <img src={siete} alt="Logo" width="50%" />
            <p className="legend">Legend 3</p>
          </div>
        </Carousel>
      </Paper>
    </View>
  );
};
