import { Button, Paper, Stack, Typography } from '@mui/material';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { StringValues } from '../../Strings';
import { View } from '../../Components/View';
import { URLS } from '../../Urls';
import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

export const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  return (
    <View>
      <Paper className={styles.contenedor} variant="outlined">
        <Typography
          gutterBottom
          variant="h3"
          component="span"
          style={{ textAlign: 'left' }}
        >
          {StringValues.HomeTitle}
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          component="span"
          className={styles.text}
          style={{ textAlign: 'left' }}
        >
          <ReactMarkdown>{StringValues.HomeText}</ReactMarkdown>
        </Typography>

        <Stack
          className={styles.buttonContainer}
          direction={'column'}
          spacing={1}
          alignItems="center"
        >
          <Button variant="outlined" onClick={() => navigate(URLS.teacherHome)}>
            {StringValues.BotonAreaProfesores}
          </Button>
          <Button variant="outlined" onClick={() => navigate('/tutorial')}>
            {StringValues.BotonDemo}
          </Button>
        </Stack>
      </Paper>
    </View>
  );
};
