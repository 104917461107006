import React, { FC } from 'react';
import { Button, Paper, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { View } from '../../Components/View';
import { LoadingPage } from '../../Components/Loading/LoadingPage';
import { UserContext } from '../../Providers/UserProvider';
import { URLS } from '../../Urls';
import { StudentProfileCard } from '../Students/StudentProfileCard';
import { RotatorioAndEvaluacionRepository } from '../../Repositories/rotatorioAndEvaluacionRepository';
import { TestType, TestWithReview } from '../../Models/Test';
import { TestTable } from './ReviewByStudentTable/TestTable';

const rotatorioAndEvaluacionRepository = new RotatorioAndEvaluacionRepository();

const FinalReviewOption: FC<{
  evaluacionFinal: TestWithReview;
  id: string;
}> = ({ evaluacionFinal, id }) => {
  const navigate = useNavigate();
  const academicString = evaluacionFinal.academicYear.replace('/', '-');
  const urlTo = `${URLS.reviewYear}/${academicString}/${id}`;

  return (
    <Paper style={{ padding: '10px' }} variant="outlined">
      Evaluación Final Disponible:
      <Button style={{ marginLeft: '20px' }} onClick={() => navigate(urlTo)}>
        Ir a Evaluación Final {evaluacionFinal.academicYear}
      </Button>
    </Paper>
  );
};

export const StudentTestList: React.FC = () => {
  const [userInfo] = React.useContext(UserContext);
  const params = useParams();
  const navigate = useNavigate();

  const studentId = params.studentId;

  const [finalReviews, setFinalReviewsReviews] = React.useState<
    TestWithReview[]
  >([]);

  React.useEffect(() => {
    if (!studentId) return;
    rotatorioAndEvaluacionRepository
      .getAll({ filter: { studentId, testType: TestType.EvaluacionAnual } })
      .then((reviews) => {
        setFinalReviewsReviews(reviews.data);
      });
  }, [studentId]);

  if (!userInfo.user) return <LoadingPage />;
  if (!studentId) {
    navigate(URLS.reviewStart);
    return <LoadingPage />;
  }

  return (
    <View>
      <Stack spacing={2}>
        <Paper style={{ padding: '10px' }} variant="outlined">
          <StudentProfileCard userId={studentId} />
        </Paper>
        {finalReviews &&
          finalReviews.map((ef) => (
            <FinalReviewOption id={studentId} evaluacionFinal={ef} />
          ))}
        <Paper style={{ padding: '10px' }} variant="outlined">
          <TestTable id={studentId}></TestTable>
        </Paper>
      </Stack>
    </View>
  );
};
