import { FC, useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material';
import { CustomToolbar } from './Toolbar';
import { TableFilterProps } from './Filters/TestFilter';
import { TestBodyLoadingRow } from './BodyRows/LoadingBodyRow';

export type FilterRow = FC<TableFilterProps<any>>;
export type BodyRowProps<T> = FC<{ data: T }>;
export type OnUpdateTable = (params: {
  page: number;
  rowsPerPage: number;
  filter: any;
}) => void;

type PaginatedTableProps<T extends any[]> = {
  title: string;
  data: T;
  count: number;
  headRow: FC;
  bodyRow: BodyRowProps<any>;
  onUpdate: OnUpdateTable;
  filterRow?: FilterRow;
  loading?: boolean;
};

type MyComponentI<T extends any[] = any[]> = FC<PaginatedTableProps<T>>;

export const PaginatedTable: MyComponentI = (props) => {
  const { data, onUpdate, count } = props;
  const [isFilter, setFilterMode] = useState(false);
  const [filter, setFilter] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    onUpdate({ page, rowsPerPage, filter });
  }, [page, rowsPerPage, filter]);

  return (
    <div>
      <CustomToolbar
        title={props.title}
        showFilterButton={!!props.filterRow}
        onFilterModeChange={() => {
          setFilterMode(!isFilter);
        }}
      />
      {isFilter && props.filterRow && (
        <props.filterRow onUpdate={(filter) => setFilter(filter)} />
      )}
      <TableContainer>
        <Table aria-label="simple table" style={{ marginBottom: '60px' }}>
          <TableHead>{<props.headRow></props.headRow>}</TableHead>
          <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {props.loading
              ? [...Array(rowsPerPage)].map(() => <TestBodyLoadingRow />)
              : data.map((u, i) => <props.bodyRow key={i} data={u} />)}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPageOptions={[5, 10, 25]}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};
