import React from "react";
import {
  TextField,
  Stack,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Alert,
} from "@mui/material";
import { Student, Gender, SchoolYear } from "../../Models/Student";
import { View } from "../../Components/View";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../Urls";
import { StudentRepository } from "../../Repositories/StudentRepository";
import { StudentList } from "./StudentsList";
import { StringValues } from "../../Strings";
import { useFormik } from "formik";
import * as Yup from "yup";

type StudentsViewProps = {
  student: Student;
  success: boolean;
  error: string | null;
  onSave: (u: Student) => void;
  onCancel: () => void;
};

const repository = new StudentRepository();

const StudentsView: React.FC<StudentsViewProps> = (props) => {

  const StudentSchema = Yup.object().shape({
    name: Yup.string().required("Campo Obligatorio"),
    surname: Yup.string().required("Campo Obligatorio"),
    birthday: Yup.date().required("Campo Obligatorio"),
    schoolYear: Yup.string().required("Campo obligatorio"),
  });

  const formik = useFormik({
    initialValues: {
      teacherId: "625ea0593cff4acc42a98951",
      birthday: new Date(),
      gender: Gender.Female,
      name: '',
      surname: '',
      schoolYear: SchoolYear.Eir1,
    },
    validationSchema: StudentSchema,
    onSubmit: async (values) => {
      const student: Student = {
        teacherId: values.teacherId,
        birthday: values.birthday,
        gender: values.gender,
        name: values.name,
        surname: values.surname,
        schoolYear: values.schoolYear,
      };
      props.onSave(student);
    },
  });

  const hasErrors = Object.keys(formik.errors).length > 0;

  return (
    <Card style={{ marginTop: "20px", padding: "20px" }} variant="outlined">
      <form onSubmit={formik.handleSubmit}>
        <CardContent>
          <Stack spacing={3}>
            <Typography
              gutterBottom
              variant="h5"
              component="span"
              style={{ textAlign: "left" }}
            >
              Añadir Alumno
            </Typography>
            {props.success && (
              <Alert
                style={{ marginTop: "30px" }}
                variant="outlined"
                severity="success"
              >
                {StringValues.CreateStudentSuccess}
              </Alert>
            )}
            {props.error && (
              <Alert
                style={{ marginTop: "30px" }}
                variant="outlined"
                severity="error"
              >
                {props.error}
              </Alert>
            )}

            <Stack direction="row" spacing={1}>
              <TextField
                required
                id="outlined-email-input"
                label="Nombre"
                name="name"
                style={{ width: "200px" }}
                error={formik.errors.name ? true : false}
                helperText={formik.errors.name ? formik.errors.name : ""}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <TextField
                required
                id="outlined-email-input"
                label="Apellidos"
                name="surname"
                style={{ width: "400px" }}
                error={formik.errors.surname ? true : false}
                helperText={formik.errors.surname ? formik.errors.surname : ""}
                onChange={formik.handleChange}
                value={formik.values.surname}
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <TextField
                required
                id="birthay"
                name="birthday"
                label="Fecha Nacimiento"
                InputLabelProps={{ shrink: true }}
                type="date"
                fullWidth={false}
                style={{ width: "200px" }}
                error={formik.errors.birthday ? true : false}
                helperText={
                  formik.errors.birthday ? formik.errors.birthday : ""
                }
                onChange={formik.handleChange}
                value={formik.values.birthday}
              />
              <FormControl>
                <InputLabel id="test-select-label">Sexo</InputLabel>
                <Select
                  labelId="gender"
                  label="Sexo"
                  id="gender"
                  name="gender"
                  onChange={formik.handleChange}
                  value={formik.values.gender}
                  style={{ width: "200px" }}
                >
                  <MenuItem value={Gender.Male}>Hombre</MenuItem>
                  <MenuItem value={Gender.Female}>Mujer</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="test-select-label">
                  Año de Residencia
                </InputLabel>
                <Select
                  labelId="Año"
                  id="schoolYear"
                  name="schoolYear"
                  label="Año de Residencia"
                  onChange={formik.handleChange}
                  value={formik.values.schoolYear}
                  style={{ width: "200px" }}
                >
                  <MenuItem value={SchoolYear.Eir1}>EIR 1</MenuItem>
                  <MenuItem value={SchoolYear.Eir2}>EIR 2</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          <Button
            size="large"
            variant="outlined"
            disabled={hasErrors}
            type="submit"
          >
            Crear Alumno
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

export const CreateStudent: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<boolean>(false);

  const defaultStudent: Student = {
    teacherId: "625ea0593cff4acc42a98951",
    birthday: new Date(),
    gender: Gender.Male,
    schoolYear: SchoolYear.Eir1,
    name: "",
    surname: "",
  };

  const onSave = (student: Student) => {
    repository
      .add(student)
      .then((response) => {
        if (response.succes) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 3000);
        } else {
          throw new Error("Error");
        }
      })
      .catch((error) => {
        setError("Error al crear el alumno");
        setTimeout(() => {
          setError(null);
        }, 5000);
      });
  };

  const onCancel = () => {
    navigate(URLS.users);
  };

  return (
    <View>
      <Stack spacing={3}>
        <StudentsView
          student={defaultStudent}
          success={success}
          error={error}
          onSave={onSave}
          onCancel={onCancel}
        />
        <StudentList />
      </Stack>
    </View>
  );
};

type ModalProps = {
  onClose: () => void;
  setStudent: (id: string) => void;
};

export const CreateStudentModal: React.FC<ModalProps> = (props) => {
  const defaultStudent: Student = {
    teacherId: "625ea0593cff4acc42a98951",
    birthday: new Date(),
    gender: Gender.Male,
    schoolYear: SchoolYear.Eir1,
    name: "",
    surname: "",
  };

  const onSave = (student: Student) => {
    repository.add(student).then((response: any) => {
      const studentId = response.data.insertedId;
      props.setStudent(studentId ?? null);
      props.onClose();
    });
  };

  return (
    <StudentsView
      student={defaultStudent}
      success={false}
      error={null}
      onSave={onSave}
      onCancel={props.onClose}
    />
  );
};
