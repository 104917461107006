import { Chip, Paper, Switch, Typography } from '@mui/material';
import styles from './styles.module.css';
import { View } from '../../../Components/View';
import Editor from '@monaco-editor/react';
import { useEffect, useState } from 'react';
import { StaticPageContent } from '../../../Components/Static';

const DEFAULT_CODE = `
// Meter aqui el titulo y el body de la pagina como lo harías en el fichero
const pantalla = {
    title: \`titulo de la pagina\`,
    body: \`body de la pagina\`
}
// no tocar esta linea
{pantalla}`;

export const Markdown = () => {
  const [editorValues, setEditorValue] = useState(DEFAULT_CODE);
  const [isEditorMode, setEditorMode] = useState(true);
  const [strings, setStrings] = useState<{ title: string; body: string }>(
    // eslint-disable-next-line no-eval
    eval(DEFAULT_CODE.trim())
  );

  const onEditorChange = (newValue: any) => {
    console.log(newValue);
    setEditorValue(newValue);
  };

  useEffect(() => {
    let evalu;
    try {
      // eslint-disable-next-line no-eval
      evalu = eval(editorValues.trim());
      setStrings(evalu);
    } catch (e) {
      console.log('evalu err', e);
      setStrings({ title: 'error', body: 'error' });
    }
    console.log('evalu pre', editorValues);
    console.log('evalu', evalu);
  }, [editorValues]);

  return (
    <View>
      <Typography
        gutterBottom
        variant="h3"
        component="span"
        style={{ textAlign: 'left' }}
      >
        Markdown
      </Typography>
      <Typography
        gutterBottom
        variant="body1"
        component="span"
        className={styles.text}
        style={{ textAlign: 'left' }}
      >
        En este módulo se muestra como usar el Markdown para crear páginas.
      </Typography>
      <div className={styles.containerCheck}>
        <Switch
          aria-label="Editor"
          onChange={() => {
            setEditorMode(!isEditorMode);
          }}
          defaultChecked
        />
        <Chip
          label={isEditorMode ? 'EDITOR MODE' : 'PREVIEW'}
          color={isEditorMode ? 'primary' : 'default'}
        />
      </div>

      {isEditorMode ? (
        <Paper className={styles.contenedor} variant="outlined" key="pape">
          <Editor
            height="400px"
            defaultLanguage="javascript"
            defaultValue={editorValues}
            onChange={onEditorChange}
          />
        </Paper>
      ) : (
        <StaticPageContent title={strings.title} body={strings.body} />
      )}
    </View>
  );
};
