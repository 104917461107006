import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';
import { View } from '../../Components/View';
import { StringValues } from '../../Strings';
import { Student } from '../../Models/Student';
import { StudentRepository } from '../../Repositories/StudentRepository';
import { LoadingPage } from '../../Components/Loading/LoadingPage';
import { CreateStudentModal } from '../Students/Students';
import { URLS } from '../../Urls';
import { RotatorioType, TestType } from '../../Models/Test';
import ReactMarkdown from 'react-markdown';
import StartIcon from '@mui/icons-material/Start';

const studentRepository = new StudentRepository();

export const StartTest: React.FC = (props) => {
  const navigate = useNavigate();

  const [students, setStudents] = React.useState<Student[] | null>(null);
  const [student, setStudent] = React.useState<Student | null>(null);
  const [academicYear, setAcedemicYear] = React.useState('2022/23');
  const [testType, setTestType] = React.useState<TestType>(
    TestType.RotatorioCSM
  );
  const [rotatorioCaption, setRotacionCaption] = React.useState<string>('');

  const [consent, setConsent] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  useEffect(() => {
    studentRepository.getAll().then((s) => {
      setStudents(s.data);
    });
  }, [student]);

  if (!students) return <LoadingPage />;

  const studentOptions = students.map((s) => {
    return { label: s.name + ' ' + s.surname, value: s.id };
  });
  const studentOption = students
    .filter((s) => s.id === student?.id)
    .map((s) => ({ label: s.name + ' ' + s.surname, value: s.id }))[0] || {
    label: 'Selecciona un alumno',
    value: '',
  };

  const isDescription =
    testType === TestType.RotatorioUHB ? !!rotatorioCaption : true;
  const canPass =
    student && consent && academicYear && testType && isDescription;

  const onStudentSelected = (id: string) => {
    const student = students.find((st) => st.id === id);
    setStudent(student!);
  };

  const onCreateStudent = (id: string) => {
    studentRepository.getById(id).then((s) => {
      setStudent(s.data);
    });
  };

  const onFinish = () => {
    if (!canPass) return;

    if (testType === TestType.EvaluacionAnual) {
      const state = {
        id: student.id,
        studentYear: student.schoolYear,
        academicYear: academicYear,
      };
      return navigate(URLS.testEvaluacion, { state });
    }
    const rotatorioType =
      testType === TestType.RotatorioCSM
        ? RotatorioType.Csm
        : RotatorioType.Uhb;
    const state = {
      id: student.id,
      studentYear: student.schoolYear,
      rotatorioType,
      rotatorioCaption,
      academicYear: academicYear,
    };
    navigate(URLS.testRotatorio, { state });
  };

  return (
    <View>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <CreateStudentModal
            onClose={() => setIsModalOpen(false)}
            setStudent={(id) => onCreateStudent(id)}
          />
        </Box>
      </Modal>

      <Paper className={styles.paper} variant="outlined">
        <Typography
          gutterBottom
          variant="h5"
          component="span"
          style={{ textAlign: 'left' }}
        >
          {StringValues.EvaluacionStartTitle}
        </Typography>
        <Stack spacing={3}>
          <Typography
            gutterBottom
            variant="body1"
            component="span"
            style={{ textAlign: 'left' }}
          >
            <ReactMarkdown>{StringValues.TestRequirements}</ReactMarkdown>
          </Typography>
          <FormControlLabel
            id={styles.startEvaluacionCheck}
            onChange={() => setConsent(!consent)}
            control={<Checkbox />}
            label={StringValues.TestCheckRequirements}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
            <Autocomplete
              disablePortal
              id={styles.startEvaluationStudentSelector}
              value={studentOption}
              options={studentOptions}
              onChange={(e, value) => onStudentSelected(value?.value ?? '')}
              renderInput={(params) => (
                <TextField {...params} label="Estudiante" />
              )}
            />
            <Button onClick={() => setIsModalOpen(true)}>
              Añadir un Alumno
            </Button>
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
            <FormControl>
              <InputLabel id="year">Año de Residencia</InputLabel>
              <Select
                style={{ minWidth: '150px' }}
                label="Año de Residencia"
                value={student?.schoolYear ?? -1}
                defaultValue={-1}
                labelId="year"
                disabled
              >
                <MenuItem value={-1}>-</MenuItem>
                <MenuItem value={1}>E.I.R. 1</MenuItem>
                <MenuItem value={2}>E.I.R. 2</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="year">Año Academico</InputLabel>
              <Select
                style={{ minWidth: '150px' }}
                label="Año academico"
                value={academicYear}
                labelId="academicYear"
                onChange={(e) => setAcedemicYear(e.target.value as string)}
              >
                <MenuItem value={'2022/23'}>2022/23</MenuItem>
                <MenuItem value={'2023/24'}>2023/24</MenuItem>
                <MenuItem value={'2024/25'}>2024/25</MenuItem>
                <MenuItem value={'2025/26'}>2025/26</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="year">Tipo de Examen</InputLabel>
              <Select
                style={{ minWidth: '300px' }}
                label="Tipo de Examen"
                value={testType}
                labelId="year"
                onChange={(e) => setTestType(e.target.value as TestType)}
              >
                <MenuItem value={TestType.RotatorioCSM}>
                  Rotatorio - Centro Salud Mental
                </MenuItem>
                <MenuItem value={TestType.RotatorioUHB}>
                  Rotatorio - Generico
                </MenuItem>
                <MenuItem value={TestType.EvaluacionAnual}>
                  Evaluacion Anual
                </MenuItem>
              </Select>
            </FormControl>
            {testType === TestType.RotatorioUHB && (
              <TextField
                style={{ width: '300px' }}
                required
                type="text"
                id="outlined-password-input"
                label="Describe la rotación generica."
                name="rotationCaption"
                onChange={(e) => setRotacionCaption(e.target.value as TestType)}
                value={rotatorioCaption}
              />
            )}
          </Stack>
        </Stack>
      </Paper>
      <Stack alignItems="end">
        <Button
          variant="outlined"
          disabled={!canPass}
          onClick={onFinish}
          endIcon={<StartIcon />}
        >
          Comenzar
        </Button>
      </Stack>
    </View>
  );
};
