import { Student } from "../Models/Student";
import { ApiRepository } from "./ApiRepository";

export class StudentRepository extends ApiRepository {
  protected baseURl = `${this.getUrl()}/student`;

  add(student: Student) {
    const url = `${this.baseURl}`;
    const body = JSON.stringify(student);
    const requestInit: RequestInit = {
      method: 'POST',
      body,
    };
    return this.request<Student>(url, requestInit);
  }

  getById(id: string) {
    const url = `${this.baseURl}/${id}`;
    const requestInit: RequestInit = { method: 'GET' };
    return this.request<Student>(url, requestInit);
  }

  getAll(params: { size?: number; page?: number } = { size: 0, page: 0 }) {
    const url = `${this.baseURl}?size=${params.size}&page=${params.page}`;
    const requestInit: RequestInit = { method: 'GET' };
    return this.request<Student[]>(url, requestInit);
  }
}
