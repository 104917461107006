import { ApiRepository } from './ApiRepository';
import { RotatorioRepository } from './RotatorioRepository';
import { EvaluacionFinalRepository } from './EvaluacionFinalRepository';
import { TestType, TestWithReview } from '../Models/Test';

const rotatorioRepository = new RotatorioRepository();
const evaluacionFinalRepository = new EvaluacionFinalRepository();

export type TestFilters = {
  studentId?: string;
  studentName?: string;
  StudentYear?: number;
  testType?: string;
};

export class RotatorioAndEvaluacionRepository extends ApiRepository {
  protected baseURl = `${this.getUrl()}/test`;

  getAll(
    params: { size?: number; page?: number; filter?: TestFilters } = {
      size: 0,
      page: 0,
    }
  ) {
    const url = `${this.baseURl}?size=${params.size}&page=${params.page}${
      !!params.filter?.testType ? '&testType=' + params.filter?.testType : ''
    }${
      !!params.filter?.studentId ? '&studentId=' + params.filter?.studentId : ''
    }`;
    if (!!params.filter?.testType)
      url.concat(`&testType=${params.filter?.testType}`);
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<TestWithReview[]>(url, requestInit);
  }

  async getAllFromStundentId(stundentId: string): Promise<TestWithReview[]> {
    const [rotatorios, evaluaciones] = await Promise.all([
      rotatorioRepository.getAllFromStudent(stundentId),
      evaluacionFinalRepository.getAllFromStudent(stundentId),
    ]);
    const evaluacionesFinales = evaluaciones.data.map((ev) => {
      return { ...ev, type: TestType.EvaluacionAnual };
    });
    return [...rotatorios.data, ...evaluacionesFinales];
  }
}
