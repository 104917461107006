import { FC, useContext } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { Roles } from '../../Models/User';
import { UserContext } from '../../Providers/UserProvider';
import { URLS } from '../../Urls';
import { LoadingPage } from '../Loading/LoadingPage';

export const PrivateRoute: FC<any> = (props) => {
  const [userInfo] = useContext(UserContext);
  const navigate = useNavigate();

  if (userInfo.loading && !userInfo.user) navigate(URLS.login);

  if (userInfo.loading) {
    return <LoadingPage />;
  }
  if (userInfo.user) {
    return <Outlet />;
  }
  return <Navigate to="/login" replace />;
};

export const AdminRoute: FC<any> = (props) => {
  const [userInfo] = useContext(UserContext);
  const navigate = useNavigate();

  if (userInfo.loading && !userInfo.user) navigate(URLS.login);

  if (userInfo.loading) {
    return <LoadingPage />;
  }
  if (userInfo.user && userInfo.user.role === Roles.Admin) {
    return <Outlet />;
  }
  return <Navigate to="/login" replace />;
};
