import { Paper, Typography } from '@mui/material';
import styles from './styles.module.css';
import { StringValues } from '../../Strings';
import { View } from '../../Components/View';
import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

export const Contact = () => {
  useEffect(() => {}, []);

  return (
    <View>
      <Paper className={styles.contenedor} variant="outlined">
        <Typography
          gutterBottom
          variant="h3"
          component="span"
          style={{ textAlign: 'left' }}
        >
          {StringValues.ContacoTitle}
        </Typography>
        <Typography
          gutterBottom
          variant="body1"
          component="span"
          className={styles.text}
          style={{ textAlign: 'left' }}
        >
          <ReactMarkdown>{StringValues.ContactoText}</ReactMarkdown>
        </Typography>
      </Paper>
    </View>
  );
};
