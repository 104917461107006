import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';

export const TestHeadRow: FC = () => {
  return (
    <TableRow>
      <TableCell>Nombre E.I.R</TableCell>
      <TableCell>Año de Residencia</TableCell>
      <TableCell>Año Academico</TableCell>
      <TableCell>Fecha de Evaluación</TableCell>
      <TableCell>Nota</TableCell>
      <TableCell>Resultado</TableCell>
    </TableRow>
  );
};
