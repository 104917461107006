import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { RubricaPage } from './rubricaPage';
import { RotatorioType, RubricaResponses } from '../../Models/Test';
import { EvaluacionFinalRepository } from '../../Repositories/EvaluacionFinalRepository';
import {
  RubicaObject,
  RubricaRepository,
} from '../../Repositories/RubricaRepository';
import { LoadingPage } from '../../Components/Loading/LoadingPage';
import { URLS } from '../../Urls';

const rubricaRepository = new RubricaRepository();
const evaluacionRepository = new EvaluacionFinalRepository();

const DEFAULT_RUBRICA: RubicaObject = { total: 9999, data: [] };

export const TestEvaluacionAnual: React.FC = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [rubrica, setRubrica] = React.useState<RubicaObject>(DEFAULT_RUBRICA);
  const [pageNum, setPageNum] = React.useState(0);
  const [responses, setResponses] = React.useState<RubricaResponses>({});
  const totalPages = rubrica?.total || 0;
  const finish = pageNum === totalPages;

  const state = location.state as {
    id: string;
    academicYear: string;
    studentYear: string;
    rotatorioType: RotatorioType;
  };
  const { id, academicYear, rotatorioType, studentYear } = state;

  useEffect(() => {
    rubricaRepository
      .getAnualRubrica()
      .then((response) => setRubrica(response.data))
      .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (finish)
      evaluacionRepository
        .add({ id, academicYear, rotatorioType, studentYear }, responses)
        .then((response) => {
          navigation(`${URLS.reviewEvaluacionFinal}/${response.data.id}`);
        });
  }, [finish]);

  const onNextPage = (r: any) => {
    const id = rubrica.data[pageNum].id;
    setResponses({ ...responses, [id]: r });
    setPageNum(pageNum + 1);
  };

  if (finish || loading) return <LoadingPage />;

  return (
    <div>
      <RubricaPage
        data={rubrica.data[pageNum]}
        allData={rubrica.data}
        page={pageNum}
        totalPage={totalPages}
        onFinish={onNextPage}
      />
    </div>
  );
};
