import { createTheme } from '@mui/material/styles';
import { red, blue, blueGrey } from '@mui/material/colors';

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: blue[500],
      contrastText: '#fff',
    },
    secondary: {
      main: blueGrey[400],
    },
    error: {
      main: red[300],
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'outlined',
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiMenuItem: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiMenu: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});
