import { Chip, TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import { TestType, TestWithReview } from '../../../Models/Test';
import { URLS } from '../../../Urls';
import { BodyRowProps } from '../paginatedTable';

const getReviewLink = (type: string, id: string) => {
  return type === TestType.EvaluacionAnual
    ? URLS.reviewEvaluacionFinal + '/' + id
    : URLS.reviewRotatorio + '/' + id;
};

export const TestBodyRow: BodyRowProps<TestWithReview> = ({ data: row }) => {
  const createdDate = row.createdAt
    ? new Date(row.createdAt).toLocaleDateString()
    : '';

  return (
    <TableRow
      style={{ border: '0' }}
      key={row.id}
      sx={{ '& th, & td': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Link
          to={getReviewLink(row.type, row.id)}
        >{`${row.student?.name} ${row.student?.surname} `}</Link>
      </TableCell>
      <TableCell>{row.studentYear}</TableCell>
      <TableCell>{row.academicYear}</TableCell>
      <TableCell>{createdDate}</TableCell>
      <TableCell>{row.review.mark}</TableCell>

      <TableCell>
        {row.review.pass ? (
          <Chip color="success" label="Apto" />
        ) : (
          <Chip color="error" label="No Apto" />
        )}
      </TableCell>
    </TableRow>
  );
};
