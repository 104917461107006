import { Breadcrumbs, Link, Paper, Typography } from '@mui/material';
import { UrlArchitecture, UrlArchitectureType } from '../../Urls';
import styles from './styles.module.css';

function getPath(
  first: boolean = true,
  urlArchitecture: UrlArchitectureType = UrlArchitecture
): UrlArchitectureType[] {
  const windowPath = window.location.pathname;
  const matchedPaths: UrlArchitectureType[] = first ? [urlArchitecture] : [];
  const matchedPath = urlArchitecture.children.find((ua) =>
    windowPath.startsWith(ua.url)
  );
  if (matchedPath) {
    matchedPaths.push(matchedPath);
    const a = getPath(false, matchedPath);
    return [...matchedPaths, ...a];
  } else {
    return matchedPaths;
  }
}

export const CustomBreadcrumb: React.FC<any> = (props) => {
  const paths = getPath();
  if (paths.length < 2) return <></>;
  return (
    <Paper variant="outlined" className={styles.container}>
      <Breadcrumbs aria-label="breadcrumb">
        {paths.map((path, i) =>
          i === paths.length - 1 ? (
            <Typography color="text.primary">{path.title}</Typography>
          ) : (
            <Link
              underline="hover"
              color="inherit"
              sx={{ display: 'flex', alignItems: 'center' }}
              href={path.url}
            >
              {path.icon && <path.icon sx={{ mr: 0.5 }} fontSize="inherit" />}
              {path.title}
            </Link>
          )
        )}
      </Breadcrumbs>
    </Paper>
  );
};
