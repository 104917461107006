import { TestType } from '../Models/Test';
import { ApiRepository } from './ApiRepository';
import { RubricaInfo } from '../Components/Rubrica';

export type RubicaObject = {
  total: number;
  data: RubricaInfo[];
};

export class RubricaRepository extends ApiRepository {
  protected baseURl = `${this.getUrl()}/rubrica`;

  get(testType: TestType) {
    if (testType === TestType.RotatorioCSM) {
      return this.getCsmRubrica();
    }
    if (testType === TestType.RotatorioUHB) {
      return this.getGenericaRubrica();
    }
    if (testType === TestType.EvaluacionAnual) {
      return this.getAnualRubrica();
    }

    throw new Error('Invalid test type');
  }

  getAll() {
    const url = `${this.baseURl}/`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<RubicaObject>(url, requestInit);
  }

  getCsmRubrica() {
    const url = `${this.baseURl}/rotacion-csm`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<RubicaObject>(url, requestInit);
  }

  getGenericaRubrica() {
    const url = `${this.baseURl}/rotacion-generica`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<RubicaObject>(url, requestInit);
  }

  getAnualRubrica() {
    const url = `${this.baseURl}/evaluacion-anual`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<RubicaObject>(url, requestInit);
  }
}
