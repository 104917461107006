import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';

export const TestHeadRow: FC = () => {
  return (
    <TableRow>
      <TableCell>Tipo</TableCell>
      <TableCell>Fecha de Evaluación</TableCell>
      <TableCell>Resultado</TableCell>
    </TableRow>
  );
};
