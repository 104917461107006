
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Card, useTheme } from '@mui/material';
import ReactMarkdown from 'react-markdown';

export const RubricaResponses: React.FC<{
  head: string[];
  responses: string[];
  onResponse: (responseNumber: number) => void;
}> = (props) => {
  const theme = useTheme();
  const [itemSelected, onChangeItemSelected] = React.useState(-2);
  const [itemHover, onChangeItemHover] = React.useState(-2);

  const onSelectResponse = (responseSelected: number) => {
    onChangeItemSelected(responseSelected);
    props.onResponse(responseSelected);
  };
  return (
    <TableContainer
      component={Card}
      sx={{
        [`& .MuiTableCell-root`]: {
          border: '1px solid rgba(224, 224, 224, 1)',
        },
        [`& .css-34nofg-MuiTableRow-root`]: {
          border: '1px solid rgba(224, 224, 224, 1)',
          marginRight: '5px',
        },
      }}
    >
      <Table sx={{ minWidth: 650, width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.head.map((r, index) => {
              const isRigthBorderSelected =
                index === itemSelected - 1 || index === itemSelected;
              const isRigthBorderHover =
                index === itemHover - 1 || index === itemHover;
              const isLeftBorderSelected =
                index === itemSelected + 1 || index === itemSelected;
              const isLeftBorderHover =
                index === itemHover + 1 || index === itemHover;
              const isTopBottomBorderSelected = index === itemSelected;
              const isTopBottomBorderHover = index === itemHover;
              const colorSelected = theme.palette.primary.main;
              const colorHover = theme.palette.primary.light;
              const colorDefault = 'rgba(224, 224, 224, 1)';

              return (
                <TableCell
                  onClick={() => onSelectResponse(index)}
                  width="33%"
                  key={r}
                  align="center"
                  onMouseEnter={() => {
                    onChangeItemHover(index);
                  }}
                  onMouseLeave={() => {
                    onChangeItemHover(-2);
                  }}
                  style={{
                    borderRightColor: isRigthBorderSelected
                      ? colorSelected
                      : isRigthBorderHover
                      ? colorHover
                      : colorDefault,
                    borderLeftColor: isLeftBorderSelected
                      ? colorSelected
                      : isLeftBorderHover
                      ? colorHover
                      : colorDefault,
                    borderTopColor: isTopBottomBorderSelected
                      ? colorSelected
                      : isTopBottomBorderHover
                      ? colorHover
                      : colorDefault,
                    borderBottomColor: isTopBottomBorderSelected
                      ? colorSelected
                      : isTopBottomBorderHover
                      ? colorHover
                      : colorDefault,
                  }}
                >
                  <ReactMarkdown>{r}</ReactMarkdown>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {props.responses.map((r, index) => {
              const isRigthBorderSelected =
                index === itemSelected - 1 || index === itemSelected;
              const isRigthBorderHover =
                index === itemHover - 1 || index === itemHover;
              const isLeftBorderSelected =
                index === itemSelected + 1 || index === itemSelected;
              const isLeftBorderHover =
                index === itemHover + 1 || index === itemHover;
              const isTopBottomBorderSelected = index === itemSelected;
              const isTopBottomBorderHover = index === itemHover;
              const colorSelected = theme.palette.primary.main;
              const colorHover = theme.palette.primary.light;
              const colorDefault = 'rgba(224, 224, 224, 1)';
              return (
                <TableCell
                  width="33%"
                  onClick={() => onSelectResponse(index)}
                  key={r}
                  onMouseEnter={() => {
                    onChangeItemHover(index);
                  }}
                  onMouseLeave={() => {
                    onChangeItemHover(-2);
                  }}
                  style={{
                    textAlign: 'left',
                    borderRightColor: isRigthBorderSelected
                      ? colorSelected
                      : isRigthBorderHover
                      ? colorHover
                      : colorDefault,
                    borderLeftColor: isLeftBorderSelected
                      ? colorSelected
                      : isLeftBorderHover
                      ? colorHover
                      : colorDefault,
                    borderTopColor: isTopBottomBorderSelected
                      ? colorSelected
                      : isTopBottomBorderHover
                      ? colorHover
                      : colorDefault,
                    borderBottomColor: isTopBottomBorderSelected
                      ? colorSelected
                      : isTopBottomBorderHover
                      ? colorHover
                      : colorDefault,
                  }}
                  align="center"
                >
                  <ReactMarkdown>{r}</ReactMarkdown>
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};