import { useState, createContext, useEffect } from "react"
import { User } from "../Models/User"
import { UserRepository } from "../Repositories/UserRepository"

export type UserStored = {
    user: User | null
    loading: boolean
}

const userRepository = new UserRepository()
const DEFAULT_USER: UserStored = { user: null, loading: true }

// Create Context Object
export const UserContext = createContext<[UserStored, (UserStore: any) => void]>([DEFAULT_USER, () => null])

// Create a provider for components to consume and subscribe to changes
export const UserProvider = (props: any) => {
    const [user, setUser] = useState<UserStored>(DEFAULT_USER)
    const handleUser = (user: User) => {
        setUser({ user, loading: false })
    }
    useEffect(() => {
        userRepository
            .getProfile()
            .then((user: any) => {
                if (!user.succes) return setUser({ user: null, loading: false })
                setUser({ user: user.data, loading: false })
            })
            .catch((e: any) => {
                // tslint:disable-next-line: no-console
                console.error("ERROR", e)
                setUser({ user: null, loading: false })
            })
    }, [])
    return <UserContext.Provider value={[user, handleUser]}>{props.children}</UserContext.Provider>
}