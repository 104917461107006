import { RubricaResponses, StudentData, TestWithReview } from '../Models/Test';
import { ApiRepository } from './ApiRepository';

type PostBody = {
  type: 'Rotatorio' | 'EvaluacionAnual';
  rotatorioType?: string;
  rotatorioCaption?: string;
  teacherId: string;
  studentId: string;
  responses: RubricaResponses;
  studentYear: string;
  academicYear: string;
};

export class RotatorioRepository extends ApiRepository {
  protected baseURl = `${this.getUrl()}/test`;

  add(
    studentData: StudentData,
    teacherId: string,
    responses: RubricaResponses
  ) {
    const body: PostBody = {
      type: 'Rotatorio',
      rotatorioType: studentData.rotatorioType,
      teacherId,
      studentId: studentData.id,
      studentYear: studentData.studentYear,
      academicYear: studentData.academicYear,
      rotatorioCaption: studentData.rotatorioCaption,
      responses,
    };
    const url = `${this.baseURl}/${studentData.id}`;
    const requestInit: RequestInit = {
      method: 'POST',
      body: JSON.stringify(body),
    };
    return this.request<{ id: string }>(url, requestInit);
  }

  getReview(id: string) {
    const url = `${this.baseURl}/${id}`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<TestWithReview>(url, requestInit);
  }

  getAll() {
    const url = `${this.baseURl}/all`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<TestWithReview[]>(url, requestInit);
  }

  getAllFromStudent(studentId: string) {
    const url = `${this.baseURl}/rotatorio/${studentId}`;
    const requestInit: RequestInit = {
      method: 'GET',
    };
    return this.request<TestWithReview[]>(url, requestInit);
  }
}
