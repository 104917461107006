import React from 'react';
import { Container } from '@mui/system';
import { Paper } from '@mui/material';
import { StudentRepository } from '../../Repositories/StudentRepository';
import { Student } from '../../Models/Student';
import {
  OnUpdateTable,
  PaginatedTable,
} from '../../Components/PaginatedTable/paginatedTable';
import { StudentBodyRow } from '../../Components/PaginatedTable/BodyRows/StudentBodyRow';
import { StudentHeadRow } from '../../Components/PaginatedTable/HeadRows/StudentHeadRow';

const studentRepository = new StudentRepository();

export const StudentList = () => {
  const [students, setStudents] = React.useState<Student[]>([]);
  const [count, setCount] = React.useState(0);

  const onUpdateQuery: OnUpdateTable = ({ page, rowsPerPage }) => {
    studentRepository.getAll({ page: page, size: rowsPerPage }).then((u) => {
      setStudents(u.data);
      setCount(u.count);
    });
  };

  return (
    <Paper variant="outlined" style={{ position: 'relative' }}>
      <Container>
        <PaginatedTable
          title="Mis Estudiantes"
          onUpdate={onUpdateQuery}
          headRow={StudentHeadRow}
          bodyRow={StudentBodyRow}
          count={count}
          data={students}
        ></PaginatedTable>
      </Container>
    </Paper>
  );
};
