import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ReactMarkdown from 'react-markdown';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Stack, Typography } from '@mui/material';
import styles from './styles.module.css';

type CustomizedAccordionProps = {
  expanded: boolean;
  isResponsed: boolean;
  onChangeExpanded: () => void;
  title: string;
  subtitle?: string;
  wrap: JSX.Element;
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  userSelect: 'none',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  cursor: 'pointer',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  cursor: 'pointer',

  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const CustomizedAccordions: React.FC<CustomizedAccordionProps> = (
  props
) => {
  const { title, wrap, isResponsed, subtitle } = props;
  return (
    <div>
      <Accordion
        expanded={props.expanded}
        onChange={() => props.onChangeExpanded()}
        sx={{
          [`& .MuiAccordionDetails-root`]: { padding: '0 !important' },
          [`& .MuiPaper-root`]: { borderRadius: '0 !important', border: 0 },
        }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Stack
            direction="row"
            spacing={2}
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            justifyItems="center"
            style={{ marginLeft: '10px' }}
          >
            {isResponsed ? (
              <CheckBoxOutlinedIcon />
            ) : (
              <CheckBoxOutlineBlankOutlinedIcon />
            )}
            <Stack spacing={0}>
              <Typography variant="body1" component="div">
                <ReactMarkdown className={styles.markdown}>
                  {title}
                </ReactMarkdown>{' '}
              </Typography>
              <Typography variant="body2" component="div">
                <ReactMarkdown className={styles.markdown}>
                  {subtitle || ''}
                </ReactMarkdown>{' '}
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{wrap}</AccordionDetails>
      </Accordion>
    </div>
  );
};
