import { Button, Paper, Step, StepLabel, Stepper } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { Rubrica, RubricaInfo } from '../../Components/Rubrica';
import { View } from '../../Components/View';

const DEFAULT_VALUES = {
  canPass: false,
  responses: {},
};

type TestProps = {
  page: number;
  data: RubricaInfo;
  allData: RubricaInfo[];
  totalPage: number;
  onFinish: (responses: { [x: number]: number }) => void;
};

export const RubricaPage: React.FC<TestProps> = (props) => {
  const currentPage = props.page;
  const [canPass, onChangeCanPass] = React.useState<boolean>(
    DEFAULT_VALUES.canPass
  );
  const [responses, onResponseChange] = React.useState<{ [x: number]: number }>(
    DEFAULT_VALUES.responses
  );

  React.useEffect(() => {
    onChangeCanPass(DEFAULT_VALUES.canPass);
    onResponseChange(DEFAULT_VALUES.responses);
  }, [props.page]);

  React.useEffect(() => {
    if (Object.keys(responses).length === props.data.rows.length) {
      onChangeCanPass(true);
    }
  }, [responses, props.totalPage]);

  const onResponse = (question: string, response: number) => {
    onResponseChange({ ...responses, [question]: response });
  };

  const onNextPageRequested = () => {
    if (canPass) {
      console.log('responses', responses);
      props.onFinish(responses);
    }
  };

  return (
    <View key={props.page}>
      <Box sx={{ width: '100%', margin: '30px 0' }}>
        <Stepper activeStep={currentPage} alternativeLabel>
          {props.allData.map((data, index) => (
            <Step key={data.id}>
              <StepLabel>{data.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Paper className="contenedor2">
        <Rubrica rubricaInfo={props.data} onResponse={onResponse} />
      </Paper>
      <div
        style={{
          display: 'flex',
          justifyContent: 'right',
          marginTop: '50px',
        }}
      >
        <Button
          variant="contained"
          disabled={!canPass}
          onClick={onNextPageRequested}
        >
          Continuar
        </Button>
      </div>
    </View>
  );
};
