import { FC } from 'react';
import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { GridFilterListIcon } from '@mui/x-data-grid';

export const CustomToolbar: FC<{
  title: string;
  showFilterButton: boolean;
  onFilterModeChange: () => void;
}> = (props) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {props.title}
      </Typography>

      {props.showFilterButton && (
        <Tooltip title="Filter list" onClick={props.onFilterModeChange}>
          <IconButton>
            <GridFilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};
