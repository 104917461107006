import React, { FC, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { StudentRepository } from '../../Repositories/StudentRepository';
import { Student } from '../../Models/Student';
import { Typography } from '@mui/material';
import styles from './styles.module.css';

const studentRepository = new StudentRepository();

const TitleField: FC = (props) => {
  return <b style={{ marginRight: '10px' }}>{props.children}</b>;
};

const ResponseField: FC = (props) => {
  return <p className={styles.responseField}>{props.children}</p>;
};

export const StudentProfileCard: React.FC<{ userId: string }> = (props) => {
  const [student, setStudent] = useState<Student | null>(null);
  useEffect(() => {
    studentRepository.getById(props.userId).then((u) => {
      setStudent(u.data);
    });
  }, [props.userId]);

  studentRepository.getAll();

  if (!student) return <div>Loading...</div>;

  return (
    <div>
      <Stack spacing={1}>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Datos del Residente.
        </Typography>
        <Stack direction={'column'} spacing={2}>
          <Stack direction={'row'} spacing={0}>
            <TitleField>Nombre Residente: </TitleField>
            <ResponseField>
              {student.name} {student.surname}
            </ResponseField>
          </Stack>
          <Stack direction={'row'} spacing={0}>
            <TitleField>Unidad Docente Multidisciplinar: </TitleField>
            <ResponseField>{student.school}</ResponseField>
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <Stack direction={'row'} spacing={0}>
              <TitleField>Edad:</TitleField>
              <ResponseField>
                {' '}
                {!!student.birthday
                  ? new Date(student.birthday).toLocaleDateString()
                  : ''}
              </ResponseField>
            </Stack>
            <Stack direction={'row'} spacing={0}>
              <TitleField>Sexo:</TitleField>{' '}
              <ResponseField>{student.gender}</ResponseField>
            </Stack>
            <Stack direction={'row'} spacing={0}>
              <TitleField>Año:</TitleField>{' '}
              <ResponseField>{student.schoolYear}</ResponseField>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
