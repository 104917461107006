const PantallaHome = {
  HomeTitle: 'Evaluación EIR Salud Mental',
  HomeText:
    'Evalúa al **EIR de Enfermería de Salud Mental** durante su residencia de forma rápida, objetiva y unificada. MÁS INFORMACIÓN sobre el [estudio](https://ecoenfsm.es/informacion) y la escala de [Evaluación EIR Salud Mental](https://ecoenfsm.es/escala)',
  BotonAreaProfesores: 'Tutores EIR',
  BotonAreaAlumnos: 'Residentes EIR Salud Mental',
  BotonDemo: 'Tutorial',
};

const PantallaAreaProfesores = {
  TeacherTitle: 'Tutores EIR',
  TeacherText: `Accede a la **Evaluación EIR Salud Mental** para medir las competencias de l@s **Residentes de Enfermería de Salud Mental** (EIR) en sus **rotatorios** y de forma **anual**`,
  BotonGestionAlumnos: 'Gestión de Alumnos',
  BotonEvalua: 'Evalua',
  BotonEvaluaciones: 'Evaluaciones',
};

const PantallaContacto = {
  ContacoTitle: 'Contacto',
  ContactoText: `## Investigador principal:
  Javier Sanz Calvo
  _Enfermero especialista en salud mental del CSM de Alcobendas_
  _Doctorando en cuidados de la salud en la Universidad Complutense de Madrid_

  🐦[@ECOEnf_SMental](https://twitter.com/ECOEnf_SMental?t=RCx6FRQ4D-pXoZy7ImgXNw&s=09)
  📍 [Evaluación EIR Salud Mental](https://goo.gl/maps/NjUvxydq3Keox9pA7)

  📧jsanzc@salud.madrid.org
  📧jsanz17@ucm.es


  ## Directores de tesis
  Dr. Luis Javier Rivera Vicente
  
  Dra. Merecedes Gómez del Pulgar García Madrid
  
  Dra. María del Carmen García Carrión
  
  ## Tutor de Doctorado
  Dr. Juan Vicente Beneit Montesinos`,
};
 const PantallaColaboradores = {
   ColaboradoresTitle: `Colaboradores`,
   ColaboradoresText: `Este proyecto ha sido posible gracias a:

   - **Mercedes Gómez del Pulgar García Madrid, Luis Javier Rivera Vicente y María del Carmen García Carrión**. _Programa de Doctorado en Cuidados de la Salud de la Facultad de Enfermería, Fisioterapia y Podología de la Universidad Complutense de Madrid_.
   - **Pilar Caminero Luna**. _Comisión Consultiva de Cuidados de la Oficina Regional de Coordinación de Salud Mental y Adicciones de la Comunidad de Madrid_ (difusión).
   - **Francisco Megias Lizancos**. _Asociación Española de Enfermería de Salud Mental_ (difusión).
   - **María Montoro Ruíz**. _Associació Catalana d'Infermeria de Salut Mental_ (difusión).
   - **Carlos Barreiro Mata**. _Ingeniero en telecomunicaciones_ (desarrollo web).
   - **Raquel Barrajón Engenios**. _Ilustradora_ (diseño gráfico).
   - Todas las Unidades Docentes Multidisciplinares de Salud Mental que han participado y tod@s los tutores/as colaboradores.
   
   Muchas gracias a tod@s.`,
 };

  const PantallaDocumentación = {
    DocumentaciónTitle: `Documentación`,
    DocumentaciónText: `## Legislación
  - Especialidades de Enfermeria [(RD 450/2005)](https://www.boe.es/eli/es/rd/2005/04/22/450).
  - Documento sobre generalidades, unidades docentes, comisiones de docencia, deberes del tutor-residente y evaluación de la Formación Sanitaria Especializada en Ciencias de la Salud [(RD 183/2008 del 8 de febrero)](https://www.boe.es/buscar/doc.php?id=BOE-A-2008-3176)
  - Programa Formativo de la Enfermera Especialista en Salud Mental [(Orden SPI/1356/2011](https://www.boe.es/eli/es/o/2011/05/11/spi1356).
  - Directrices básicas de la evaluación a especialistas en formación [(Resolución de 21 de marzo de 2018)](https://www.boe.es/boe/dias/2018/04/19/pdfs/BOE-A-2018-5385.pdf)
  - Informe de evaluación de rotación [(Ministerio de Sanidad)](https://www.sanidad.gob.es/areas/profesionesSanitarias/formacionEspecializada/registroNacional/uniDocentes/docs/2021InformeEvaluacionRotacion.pdf).
  - Informe de evaluación anual [(Ministerio de Sanidad)](https://www.sanidad.gob.es/areas/profesionesSanitarias/formacionEspecializada/registroNacional/uniDocentes/docs/2021InfoEvalcAnualTutor.pdf).
    
## Documentos técnicos (tesis)
  - Evaluación de competencias del enfermero especialista en salud mental, utilizando la escala ECOEnf [(Escala ECOEnfSM)](https://eprints.ucm.es/id/eprint/65242/1/T42384.pdf).
  - Evaluación de compentencias en el Espacio Europeo de Educación Superior: un instrumento para el Grado en Enfermería [(Escala ECOEnf)](https://eprints.ucm.es/id/eprint/21343/1/T33395.pdf).
    
## Documentación útil del estudio
  - [Escala ECOEnfSM completa](https://drive.google.com/file/d/1uZ5kqvdzO-3KyynK9lXQPrDO6IyBRq60/view?usp=sharing).
  - [Nivel mínimo de logro exigible de la escala ECOEnfSM](https://drive.google.com/file/d/13lLtugk2gpUrAmjW_kPHM9mx_LkJtmSX/view?usp=sharing).
  - [Consetimiento informado](https://docs.google.com/document/d/1lLrspySFyCyCYoQB8m5YFR6Ynbfito7Z/edit?usp=sharing&ouid=116980082303061624264&rtpof=true&sd=true).
  - [Respuestas correctas según año de residencia](https://drive.google.com/file/d/1GCxCpFOLabA4FmvuoGILJ8vUpsqOEejC/view?usp=sharing)`,
};

  const PantallaPrivacidad = {
    PrivacidadTitle: `Pólitica de Privacidad`,
    PrivacidadText: `La presente Política de Privacidad establece los términos en que www.ecoenfsm.es usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Esta herramienta web y los investigadores que la han desarrollado están comprometidos con la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para asegurarse que está de acuerdo con dichos cambios.

##	Información que es recogida.
Nuestro sitio web podrá recoger información personal como, por ejemplo: nombre, apellidos, fecha de nacimiento, sexo, información de contacto como su dirección de correo electrónico e información demográfica o laboral como su puesto de trabajo o la Unidad Docente Multidisciplinar de Salud Mental y la provincia donde desarrolla su actividad profesional como tutor o tutora de residentes de Enfermería de Salud Mental. Así mismo, cuando sea necesario, se podrá requerir información específica para procesar alguna otra demanda o que pueda ser relevante para el desarrollo del estudio.

##	Uso de la información recogida.
Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener un registro de usuarios y mejorar nuestros productos y servicios. Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio. Estos correos electrónicos serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.
Los investigadores a cargo del dominio www.ecoenfsm.es están altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.

##	Utilización de cookies.
Una cookie se refiere a un fichero que es enviado con la finalidad de solicitar permiso para almacenarse en su ordenador. Al aceptar dicho fichero se crea y la cookie sirve entonces para tener información respecto al tráfico web, facilitando las futuras visitas a una web recurrente. Otra función de las cookies es que con ellas las webs pueden reconocerte individualmente y por tanto brindarte el mejor servicio personalizado.
Nuestro sitio web emplea las cookies para poder identificar las páginas que son visitadas, su frecuencia y tiempo de permanencia. Esta información será empleada con fines investigadores y para realizar un análisis estadístico que nos permita mejorar la navegabilidad de nuestro sitio web. Usted puede eliminar las cookies en cualquier momento desde su ordenador. Sin embargo, las cookies ayudan a proporcionar un mejor servicio de los sitios web y no dan acceso a información de su ordenador ni de usted, a menos de que usted así lo quiera y la proporcione directamente. Usted puede aceptar o negar el uso de cookies, sin embargo, la mayoría de navegadores aceptan cookies automáticamente pues sirve para tener un mejor servicio web. También usted puede cambiar la configuración de su ordenador para declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.

##	Enlaces a Terceros.
Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos enlaces y abandone nuestra página, ya no tenemos control sobre el sitio al que es redirigido y por lo tanto no somos responsables de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.

##	Control de su información personal.
En cualquier momento usted puede restringir la recopilación o el uso de la información personal que es proporcionada a nuestro sitio web.
Los datos recogidos en este sitio web se utilizarán únicamente con fines investigadores para la realización de una tesis doctoral dirigida por la Facultad de Enfermería, Fisioterapia y Podología de la Universidad Complutense de Madrid. Tras la finalización de la tesis doctoral, podrán ser usados con fines asistenciales y evaluativos hacia residentes de formación especializada en Enfermería de Salud Mental.
Esta web no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden judicial.
Los investigadores vinculados al dominio www.ecoenfsm.es, se reservan el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento.
`,
  };

 const PantallasEvaluaciones = {
   EvaluacionStartTitle: 'Evaluación EIR Salud Mental',
   EvaluacionStartBody: `Seleccione un alumno para poder acceder a sus evaluaciones. `,
 };

 let PantallaInformacion = {
   InfoTitle: '¿En qué consiste el estudio?',
   InfoBody: `
  # Justificación
La **Enfermería de Salud Mental** cuenta con un programa formativo ([Orden SPI/1356/2011 de 11 de mayo](https://www.boe.es/eli/es/o/2011/05/11/spi1356)) cuyo objetivo es la **adquisición de competencias profesionales**.

El seguimiento y calificación de las competencias durante la residencia se llevará a cabo mediante una **evaluación formativa continua, anual y final** ([RD 183/2008 del 8 de febrero](https://www.boe.es/buscar/doc.php?id=BOE-A-2008-3176)).  

### El programa formativo indica: 
- ¿_Qué evaluar_? -- competencias profesionales.
- ¿_Dónde evaluar_? -- rotatorios.
- ¿_Cuánto evaluar_? -- actividades mínimas a realizar.
- ¿_Cuándo evaluar_? -- en cada [rotación](https://www.sanidad.gob.es/areas/profesionesSanitarias/formacionEspecializada/registroNacional/uniDocentes/docs/2021InformeEvaluacionRotacion.pdf) y de forma [anual](https://www.sanidad.gob.es/areas/profesionesSanitarias/formacionEspecializada/registroNacional/uniDocentes/docs/2021InfoEvalcAnualTutor.pdf).

### pero no indica "_**CÓMO EVALUAR**_" al EIR. 

> [La escala de Evaluación de Competencias de la Enfermería de Salud Mental](https://ecoenfsm.es/escala).

## Diseño de estudio
Estudio piloto de **aplicabilidad de la escala ECOEnfSM** para la Evaluación EIR Salud Mental. Tesis del _Programa de Doctorado de la Facultad de Enfermería, Fisioterpia y Podología de la Universidad Complutense de Madrid_ que pretende homogeneizar las evaluaciones en todas la Unidades Docentes Españolas.

## Objetivo del estudio
Evaluación de las **competencias profesionales** de los Enfermeros Internos Residentes (EIR) de **Enfermería de Salud Mental** de las UDM de Salud Mental durante su residencia.

## Consideraciones éticas
Se solicita expresa autorización de los tutores generales encargados de la **Evaluación del EIR Salud Mental** para utilizar sus datos con fines investigadores. 
Se informará previamente a la evaluación del EIR de Salud Mental sobre el contenido del estudio, objetivos y tratamiento de sus datos a través de una hoja informativa junto con el [consentimiento informado](https://docs.google.com/document/d/1lLrspySFyCyCYoQB8m5YFR6Ynbfito7Z/edit?usp=sharing&ouid=116980082303061624264&rtpof=true&sd=true).

Este proyecto ha sido aceptado por el **Comité Ético de Investigación Clínica del Hospital Clínico San Carlos** y por la **Comisión de Investigación del Hospital Universitario Infanta Sofía**.
`,
 };

 const PantallaSituacion = {
   SituacionTitle:
     'Actualidad y reconocimiento de la Enfermería de Salud Mental',
   SituacionBody: `
## Reconocimiento Enfermería Salud Mental
La Enfermera Especialista en Salud Mental se remonta al 1998, pero aún **no se ha reconocido de la misma forma en las distintas comunidades autónomas españolas**.

En 2016 solamente estaba reconocido en 8 comunidades españolas: 

> Galicia, Cantabria, Aragón, Comunidad Valenciana, Extremadura, Murcia, Islas Baleares e Islas Canarias.

Actualmente hay un mayor reconomiento de la Enfermera Especialista en Salud Mental. 
Consulte el [marco lesgilativo actualizado](https://www.aeesme.org/wp-content/uploads/2022/05/Mayo-2022-Actualizacio%CC%81n-Estado-Reconocimiento-Enf-Esp-SM-2022.pdf) (mayo 2022) desarrollado por la Asociación Española de Enfermería de Salud Mental.

## Actualidad Enfermería Salud Mental
### Bolsas de Enfermero/a Especialista en Salud Mental
Se han desarrollado bolsas de trabajo específicas en 14 CCAA en España (_actualizado julio 2022_):

| Comunidad Autónoma | Bolsa específica ENF Salud Mental |
| ------ | ------ |
| Andalucia | Sí [Aquí](http://www.sspa.juntadeandalucia.es/servicioandaluzdesalud/profesionales/personaltemporal/estado.asp?clasif_cp=&clasif_esp=85) | 
| Aragón | Sí [Aquí](https://www.aragon.es/-/enfermeria-de-salud-mental) | 
| Canarias | Sí [Aqui](https://www3.gobiernodecanarias.org/sanidad/scs/contenidoGenerico.jsp?idDocument=43eb7692-ff2c-11ea-a066-6d12f08e2db3&idCarpeta=decafabb-b7d9-11e7-a18d-018d8523a46d) |
| Cantabria | Sí [Aquí](https://dgrrhh.scsalud.es/pet/jsp/login.jsp)|  
| Castilla-La Mancha | Sí [Aquí](https://sanidad.castillalamancha.es/profesionales/atencion-al-profesional/bolsas-constituidas/baremos/personal-sanitario-diplomado) |
| Castilla y León | Sí [Aquí](https://www.saludcastillayleon.es/profesionales/es/procesos_selectivos/nuevo-procedimiento-bolsas-empleo/convocatorias-abiertas/enfermero-especialista-salud-mental) | 
| Cataluña | No |  
| Ceuta | No  |
| Comunidad de Madrid | Sí [Aquí](https://www.comunidad.madrid/servicios/salud/bolsa-empleo-temporal-enfermeroa-especialista-enfermeria-salud-mental) | 
| Comunidad Foral de Navarra | Sí [Aquí](https://car.navarra.es/CAR/PreLoginCert.aspx?id=50296&referrer=https%3a%2f%2fadministracionelectronica.navarra.es%2fRRHH.SIMPA.ContratacionSalud.WebInscripcion%2f&serviceDescription=Listas+de+contrataci%u00f3n+temporal+Salud&ShowCredentials=&SkipRepInfo=) | 
| Comunidad Valenciana | Sí [Aquí](https://www.gva.es/es/inicio/atencion_ciudadano/buscadores/busc_empleo_publico/detalle_oposiciones?id_emp=63819) |  
| Extremadura | Sí [Aquí](https://saludextremadura.ses.es/bolsa/empleopublico.php) |
| Galicia | Sí [Aquí](https://www.sergas.es/Recursos-Humanos/Enfermeiro-especialista-en-sa%C3%BAde-mental?idioma=es) |
| Islas Baleares | Sí [Aquí](https://www.ibsalut.es/es/profesionales/recursos-humanos/trabaja-con-nosotros/bolsa-unica/bolsa?conv_id=32) | 
| La Rioja | No |
| Melilla | No | 
| País Vasco | Sí [Aquí](https://hh-sp.osakidetza.eus/B96/informacion_convocatoria.xhtml) |  
| Principado de Asturias | No | 
| Región de Murcia | Sí [Aquí](https://www.murciasalud.es/bolsas.php?op=mostrar_bolsa&id_bolsa=81&idsec=39) |

### Oposiciones de Enfermero/a Especialista en Salud Mental
La creación de OPE de Enfermería de Salud Mental es reciente, ya que las primeras surgieron en 2017 y hasta la actualidad se han convocado solamente en España 600 plazas aproximadamente.

Puede consultar el histórico de [**oposiciones específicas de Enfermería de Salud Mental por comunidades autónomas**](https://www.canaloposiciones.com/ultimas_oposiciones_enfermero_a_en_salud_mental-pro1072ap1.htm#/exploraIni).`,
 };

 const PantallaEscala = {
   EscalaTitle: 'Escala Evaluación EIR Salud Mental',
   EscalaBody: `La Escala de [Evaluación de las competencias enfermeras en salud mental](https://eprints.ucm.es/id/eprint/65242/1/T42384.pdf) (ECOEnfSM) es un instrumento desarrollado por Rivera Vicente en 2021 para la evaluación del **E.I.R.** **Salud Mental**. 
   
Está compuesta por 8 unidades de compentencia (UC):

  - 4 unidades específicas (UCE) y 4 transversales (UCT).
  - 62 criterios de evaluación. Cada criterio consta de **tres niveles de consecución de objetivos**: _nivel básico, medio y avanzado_.
  - 186 descriptores de nivel. 

Las 8 UC que la forman son:

## Unidades de competencia específicas (UCE)
Son rúbricas de evaluación de competencias EIR Salud Mental compuestas entre 7 o 9 criterios. Útil para la Evaluación EIR Salud Mental **en un rotatorio específico** (Centro de Salud Mental, Unidades de Media Estancia, etc.) y son evaluadas cada 2-6 meses.

1. **Valoración y Diagnótico**
> Entrevista clínica, Identificación de factores de riesgo y situaciones de crisis, Valoración por patrones en salud mental, Diagnóstico enfermero, Grupo familia/red de apoyo, Recursos sociosanitarios, Transculturalidad, Tratamientos biológicos y Visita domiciliaria.

2. **Planificación**
> Planificación de cuidados, Adecuación del plan, Utilización de protocolos, Planificación de riesgos, Taxonomías enfermeras, Planificación de recursos y Visita domiciliaria.

3. **Intervención**
> Intervención individual, Intervención familiar, Intervención grupal, Intervención en crisis, Tratamientos biológicos, Visita domiciliaria y Derivación al alta.

4. **Evaluación**
> Intervención individual, Intervención familiar, Intervención grupal, Intervención en crisis, Cuidados de enfermería en salud mental, Tratamientos biológicos, Visita domiciliaria y Evaluación de programas y protocolos.

## Unidades de competencia transversales (UCT)
Son rúbricas de evaluación de competencias EIR Salud Mental compuestas entre 7 o 8 crriterios. Útil para la Evaluación EIR Salud Mental **a lo largo del programa formativo**, es decir, de forma anual.

5. **Investigación**
> Actitud observadora y reflexiva, Actitud de aprendizaje y mejora, Cuestionar la realidad, Desarrollar protocolos, Trabajos científicos, La pregunta de investigación, Búsqueda de información y Divulgación de resultados.

6. **Gestión clínica**
> Gestión por procesos, Liderazgo, Mejora de la calidad de la actividad asistencial, Mejora de la calidad de los servicios de salud mental, Medida de la calidad, Sistemas de información y Políticas sanitarias.

7. **Docencia**
> Actitud reflexiva sobre la importancia legal y ética, Evaluar necesidades de intervención de educación para la salud, Planificación de las intervenciones, Realizar intervenciones individuales y grupales, Programas de educación para la salud, Autoaprendizaje, Actitud de mejora continua y Actividades docentes.

8. **Comunicación y Relación interpersonal**
> Escucha activa, Expresión verbal, Material de apoyo a la comunicación y relación interpersonal, Relación interpersonal, Contexto multicultural, Resolución de conflictos, Manejo de las tecnologías de la información y Comunicación y relación interprofesional.

## La evaluación EIR Salud Mental es positiva su supera:

-	El **número mínimo de Criterios de evaluación** para cada UC.
-	Los **Criterios imprescindibles** de cada UC.
- Las 8 UC con una **calificación de al menos 5/10** (_nº criterios superados/nº criterios totales x 10_).

## La evaluación EIR Salud Mental esta adaptada a la rotación:

| Centro de Salud Mental | Nº mínimo de criterios a superar | Criterios imprescindibles |
| ------ | ------ | ------ |
| Valoración y Diagnóstico | 6/9 | 1, 4, 8 y 9 | 
| Planificación | 4/7 | 3, 4, 5 y 7 | 
| Intervención | 5/7 | 4, 5, 6 y 7 | 
| Evaluacion | 5/8 | 4, 5, 6 y 7 |  
| Comunicación interpersonal | 5/8 | 1, 4, 6 y 8 | 


| UHB, UHTR, UCPP, HD, etc.) | Nº mínimo de criterios a superar | Criterios imprescindibles |
| ------ | ------ | ------ |
| Valoración y Diagnóstico | 5/8 | 1, 4 y 8 | 
| Planificación | 4/6 | 3, 4 y 5 | 
| Intervención | 4/6 | 4, 5 y 7 | 
| Evaluacion | 4/7 | 4, 5 y 6 |  
| Comunicación interpersonal | 5/8 | 1, 4, 6 y 8 | 


| Evaluación anual | Nº mínimo de criterios a superar | Criterios imprescindibles |
| ------ | ------ | ------ |
| Investigación | 5/8 | 1, 5 y 7 | 
| Gestión clínica | 3/7 | 3 | 
| Docencia | 5/8 | 1, 4 y 8 | 

La superación de los criterios de evaluación depende del año de residencia. Más exigente para EIR2 que para EIR1. 

> ["Nivel mínimo de logro exigible"](https://drive.google.com/file/d/13lLtugk2gpUrAmjW_kPHM9mx_LkJtmSX/view?usp=sharing).

> [RESPUESTAS CORRECTAS SEGÚN AÑO DE RESIDENCIA](https://drive.google.com/file/d/1GCxCpFOLabA4FmvuoGILJ8vUpsqOEejC/view?usp=sharing)


Finalmente, cuando ya han sido evaluadas todas la UC, se calcula la NOTA final:

| Unidades de competencia | % UC | % UCE/UCT |
| ------ | ------ | ------ |
| Valoración y Diagnóstico | 25% | 70% |
| Planificación | 25% |  |
| Intervención | 25% |  |
| Evaluacion | 25% |  |
| Investigación | 25% | 30% |
| Gestión clínica | 5% |  |
| Docencia | 20% |  |
| Comunicación interpersonal | 50% |  |

## Ventajas de la Evaluación EIR Salud Mental (ECOEnfSM)

👌 Específica para Evaluación EIR Salud Mental.

✅ Validada.
   
🇪🇸 Unificada a nivel nacional y basada en [Orden SPI/1356/2011](https://www.boe.es/boe/dias/2011/05/24/pdfs/BOE-A-2011-9081.pdf).
   
📲 Accesible desde cualquier dispositivo electrónico.

💲 Gratuita.
   
🔄 Feed-back inmediato de resultados de evaluación.

📋 Evaluación EIR Salud Mental completa.

🔍 Más objetiva.`,
 };

 const PantallaHistoria = {
   HistoriaTitle: '¿Quién es el Enfermero Interno Residente de Salud Mental?',
   HistoriaBody: `La formación del Enfermero Interno Residente (EIR) se realiza mediante la superación de un examen nacional anual, tras el cual el residente puede optar a una de las [especialidades contempladas en España](https://www.boe.es/diario_boe/txt.php?id=BOE-A-2005-7354#:~:text=Las%20especialidades%20de%20Enfermer%C3%ADa%20son,c%20Enfermer%C3%ADa%20Geri%C3%A1trica.).

Se trata de una formación:
- Sin ningún coste y con contrato en formación remunerado.
- Con un programa estructurado, supervisado y vigilado por el Ministerio de Sanidad español.
- Duración: 2 años.
- Título de Enfermera Especialista necesario para optar a puestos, bolsas de trabajo u oposiciones específicas de tu especialidad en España.
  
## Programa Formativo de la Enfermería Salud Mental 
La Especialidad de **Enfermería de Salud Mental** cuenta con un programa formativo ([Orden SPI/1356/2011 de 11 de mayo](https://www.boe.es/eli/es/o/2011/05/11/spi1356)) donde la enfermera especialista en Salud Mental es definida como:

> "_El profesional sanitario que proporciona una atención especializada en salud mental mediante la prestación de cuidados de enfermería_".

Para obtener el título de **Enfermería Especialista en Salud Mental**, las enfermeras residentes deben cumplir el programa formativo en una de las [Unidades Docentes Multidisciplinares de Salud Mental acreditadas](https://fse.mscbs.gob.es/fseweb/view/public/fse/centrosAcreditados/busquedaCentros.xhtml) (UDMSM) durante un período de **2 años** a tiempo completo. 

> El objetivo del programa es la **adquisición de competencias** (asistenciales,docentes, investigadoras y de gestión)

La _Comisión Nacional_ propone un itinerario formativo, aunque cada UDMSM organiza estas rotaciones acorde a su situación:

| Primer año | Duración (1) |
| ------ | ------ |
| Unidades de Rehabilitación de Larga Estancia | 2 meses |
| Unidades de Hospitalización de Agudos | 6 meses |
| Unidades Especiales (2) | 3 meses |


| Segundo año | Duración (1) |
| ------ | ------ |
| Unidades de Media Estancia (rehabilitación comunitaria)  | 4 meses |
| Centro de Salud Mental Comunitario | 5 meses |
| Programa Salud Mental Infanto Juvenil | 2 meses |

(1)_Se contabilizan 22 meses sin tener en cuenta períodos vacacionales_.

(2)_Adicciones, Hospital de día, Unidad de Psicosis Refractaria, Unidad de Patología Dual, Unidad De Trastornos de la Conducta Alimentaria, Unidad de Trastornos Psicosomáticos, Otras Unidades o Programas específicos_.

La formación teórica y práctica clínica se lleva a cabo durante la jornada laboral, al margen de las horas de **atención continuada** y personales de estudio. También, se realizan **guardias presenciales**, siendo lo recomendado 2 o 3 mensuales.

> _Las rotaciones incluidas, sus duraciones, las horas de atención continuada y el número de guardias dependen de la UDM_.

### Tutorización de Evaluación EIR Salud Mental
El residente contará con la figura del tutor, que deberá ser un especialista de enfermería de salud mental, como primer responsable del proceso de aprendizaje.
La metodología docente se basa en el **aprendizaje autónomo tutorizado** y en la **práctica asistencia**, junto con otras actividades formativas (sesiones clínicas, diarios, eventos científicos, revisiones bibliográficas, etc.) que deben constar en el Libro del Residente.

El residente debe **asumir de forma progresiva responsabilidades**, con un nivel decreciente de supervisión a medida que avance el programa, hasta alcanzar el ejercicio autónomo de la profesión.

Se realizará una **evaluación continua** donde se evaluan las **competencias adquiridas** [en cada rotatorio](https://www.sanidad.gob.es/areas/profesionesSanitarias/formacionEspecializada/registroNacional/uniDocentes/docs/2021InformeEvaluacionRotacion.pdf) y de forma [anual](https://www.sanidad.gob.es/areas/profesionesSanitarias/formacionEspecializada/registroNacional/uniDocentes/docs/2021InfoEvalcAnualTutor.pdf), dando resultado de _APTO_ o _NO APTO_. 

> Le evaluación es **genérica** para todas las Formaciones Sanitarias Especializadas (Biología, Enfermería, Farmacia, Física, Medicina, Psicología y Química) y para todas sus especialidades.`,
 };

 const PantallasTest = {
   TestStartTitle: 'Evaluación EIR Salud Mental',
   TestRequirements: `Se garantiza la confidencialidad de la participación. La información recogida será anónima y no se usará para ningún propósito diferente a los de esta investigación. Su participación es voluntaria. Si decide no participar, ello no tendrá ninguna repercusión negativa. En cumplimiento del [Reglamento Europeo de Protección de Datos](https://www.boe.es/doue/2016/119/L00001-00088.pdf) y [la Ley Orgánica 3/2018 del 5 de diciembre de Protección de Datos Personales y garantía de los derechos digitales](https://www.boe.es/buscar/doc.php?id=BOE-A-2018-16673), el consentimiento para el tratamiento y la cesión de sus datos personales es revocable. Usted puede ejercer el derecho en cualquier momento dirigiéndose al [investigador principal](https://ecoenfsm.es/contacto).  
  
  Este estudio ha sido evaluado favorablemente por el Comité Ético de Investigación Clínica del Hospital Clínico San Carlos y la Comisión de Investigación del Hospital Universitarios Infanta Sofía.
  
  Desde ya le agradecemos su participación. Consulte el resto del [consetimiento informado](https://docs.google.com/document/d/1lLrspySFyCyCYoQB8m5YFR6Ynbfito7Z/edit?usp=sharing&ouid=116980082303061624264&rtpof=true&sd=true) o más información [aquí](https://ecoenfsm.es/informacion)`,
   TestCheckRequirements: 'HE LEÍDO Y ACEPTO PARTICIPAR EN EL ESTUDIO',
 };

 const Menu = {
   MenuInfo: 'Información',
   MenuEscala: 'Escala ECOEnfSM',
   MenuHistoria: 'EIR Salud Mental',
   MenuActualidad: 'Actualidad ENF Salud Mental',
   MenuTutores: 'Evaluación EIR Salud Mental',
   MenuDocumentacion: 'Documentación',
   MenuColaboradores: 'Colaboradores',
   MenuContacto: 'Contacto',
 };

 export const StringValues = {
   TopBarTitle: 'Evaluación EIR Salud Mental',

   ...PantallaInformacion,
   ...PantallasTest,
   ...PantallasEvaluaciones,
   ...PantallaAreaProfesores,
   ...PantallaHome,
   ...Menu,
   ...PantallaSituacion,
   ...PantallaEscala,
   ...PantallaHistoria,
   ...PantallaContacto,
   ...PantallaColaboradores,
   ...PantallaDocumentación,
   ...PantallaPrivacidad,

   PageNotFoundText: 'Página no encontrada',
   LoginTitle: 'Iniciar Sesión',
   LoginError: 'Usuario o contraseña incorrectos.',
   CreateStudentError:
     'Error al crear el estudiante. Faltan campos obligatorios.',
   CreateStudentSuccess: 'Estudiante creado correctamente.',
 };

 export const CCAA = [
   { label: 'Andalucia', comunidad: 'Andalucia' },
   { label: 'Aragón', comunidad: 'Aragón' },
   { label: 'Canarias', comunidad: 'Canarias' },
   { label: 'Cantabria', comunidad: 'Cantabria' },
   { label: 'Castilla - La Mancha', comunidad: 'Castilla - La Mancha' },
   { label: 'Castilla y León', comunidad: 'Castilla y León' },
   { label: 'Cataluña', comunidad: 'Cataluña' },
   { label: 'Comunidad de Madrid', comunidad: 'Comunidad de Madrid' },
   {
     label: 'Comunidad Foral de Navarra',
     comunidad: 'Comunidad Foral de Navarra',
   },
   { label: 'Comunidad Valenciana', comunidad: 'Comunidad Valenciana' },
   { label: 'Extremadura', comunidad: 'Extremadura' },
   { label: 'Galicia', comunidad: 'Galicia' },
   { label: 'Islas Baleares', comunidad: 'Islas Baleares' },
   { label: 'La Rioja', comunidad: 'La Rioja' },
   { label: 'País Vasco', comunidad: 'País Vasco' },
   { label: 'Principado de Asturias', comunidad: 'Principado de Asturias' },
   { label: 'Región de Murcia', comunidad: 'Región de Murcia' },
 ];

 export const Provincias = [
   { label: 'A Coruña', provincia: 'A Coruña' },
   { label: 'Álava', provincia: 'Álava' },
   { label: 'Albacete', provincia: 'Albacete' },
   { label: 'Alicante', provincia: 'Alicante' },
   { label: 'Almeria', provincia: 'Almeria' },
   { label: 'Badajoz', provincia: 'Badajoz' },
   { label: 'Barcelona', provincia: 'Barcelona' },
   { label: 'Cáceres', provincia: 'Cáceres' },
   { label: 'Cadiz', provincia: 'Cadiz' },
   { label: 'Cantabria', provincia: 'Cantabria' },
   { label: 'Castellón', provincia: 'Castellón' },
   { label: 'Ciudad Real', provincia: 'Ciudad Real' },
   { label: 'Córdoba', provincia: 'Córdoba' },
   { label: 'Gipuzkoa', provincia: 'Gipuzkoa' },
   { label: 'Girona', provincia: 'Girona' },
   { label: 'Granada', provincia: 'Granada' },
   { label: 'Guadalajara', provincia: 'Guadalajara' },
   { label: 'Huelva', provincia: 'Huelva' },
   { label: 'Huesca', provincia: 'Huesca' },
   { label: 'Jaén', provincia: 'Jaén' },
   { label: 'La Rioja', provincia: 'La Rioja' },
   { label: 'Las Palmas', provincia: 'Las Palmas' },
   { label: 'León', provincia: 'León' },
   { label: 'Lleida', provincia: 'Lleida' },
   { label: 'Lugo', provincia: 'Lugo' },
   { label: 'Madrid', provincia: 'Madrid' },
   { label: 'Málaga', provincia: 'Málaga' },
   { label: 'Mallorca', provincia: 'Mallorca' },
   { label: 'Ourense', provincia: 'Ourense' },
   { label: 'Palencia', provincia: 'Palenca' },
   { label: 'Pamplona', provincia: 'Pamplona' },
   { label: 'Pontevedra', provincia: 'Pontevedra' },
   { label: 'Principado de Asturias', provincia: 'Principado de Asturias' },
   { label: 'Región de Murcia', provincia: 'Región de Murcia' },
   { label: 'Salamanca', provincia: 'Salamanca' },
   { label: 'Santa Cruz de Tenerife', provincia: 'Santa Cruz de Tenerife' },
   { label: 'Segovia', provincia: 'Segovia' },
   { label: 'Sevilla', provincia: 'Sevilla' },
   { label: 'Soria', provincia: 'Soria' },
   { label: 'Tarragona', provincia: 'Tarragona' },
   { label: 'Teruel', provincia: 'Teruel' },
   { label: 'Toledo', provincia: 'Toledo' },
   { label: 'Valencia', provincia: 'Valencia' },
   { label: 'Valladolid', provincia: 'Valladolid' },
   { label: 'Vizcaya', provincia: 'Vizcaya' },
   { label: 'Zamora', provincia: 'Zamora' },
   { label: 'Zaragoza', provincia: 'Zaragoza' },
 ];

 export const UnidadDocentes = [
   {
     label: 'UDM SALUD MENTAL A.G.S CAMPO DE GIBRALTAR (H. PUNTA DE EUROPA)',
     unidad: 'UDM SALUD MENTAL A.G.S CAMPO DE GIBRALTAR (H. PUNTA DE EUROPA)',
   },
   {
     label: 'UDM SALUD MENTAL C. H. TORRECARDENAS DE ALMERÍA',
     unidad: 'UDM SALUD MENTAL C. H. TORRECARDENAS DE ALMERÍA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO REINA SOFÍA',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO REINA SOFÍA',
   },
   {
     label: 'UDM SALUD MENTAL COMPLEJO ASISTENCIAL DE SEGOVIA',
     unidad: 'UDM SALUD MENTAL COMPLEJO ASISTENCIAL DE SEGOVIA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO INFANTA SOFÍA',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO INFANTA SOFÍA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. ROYO VILLANOVA',
     unidad: 'UDM SALUD MENTAL DEL H. ROYO VILLANOVA',
   },
   {
     label: 'UDM SALUD MENTAL H. DE PONIENTE',
     unidad: 'UDM SALUD MENTAL H. DE PONIENTE',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO SAN CECILIO DE GRANADA',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO SAN CECILIO DE GRANADA',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO VIRGEN DE LAS NIEVES',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO VIRGEN DE LAS NIEVES',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. JUAN RAMÓN JIMÉNEZ',
     unidad: 'UDM SALUD MENTAL DEL H. JUAN RAMÓN JIMÉNEZ',
   },
   {
     label: 'UDM SALUD MENTAL DEL C.H. JAÉN',
     unidad: 'UDM SALUD MENTAL DEL C.H. JAÉN',
   },
   {
     label: 'UDM SALUD MENTAL H. DE JEREZ DE LA FRONTERA',
     unidad: 'UDM SALUD MENTAL H. DE JEREZ DE LA FRONTERA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO VIRGEN DE LA VICTORIA',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO VIRGEN DE LA VICTORIA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. REGIONAL UNIVERSITARIO DE MÁLAGA',
     unidad: 'UDM SALUD MENTAL DEL H. REGIONAL UNIVERSITARIO DE MÁLAGA',
   },
   {
     label: 'UDM SALUD MENTAL H. DE MOTRIL-AGS SUR GRANADA',
     unidad: 'UDM SALUD MENTAL H. DE MOTRIL-AGS SUR GRANADA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO DE PUERTO REAL',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO DE PUERTO REAL',
   },
   {
     label: 'UDM SALUD MENTAL  H. UNIVERSITARIO VIRGEN DE LA MACARENA',
     unidad: 'UDM SALUD MENTAL  H. UNIVERSITARIO VIRGEN DE LA MACARENA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO NTRA SRA. DE VALME',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO NTRA SRA. DE VALME',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO VIRGEN DEL ROCÍO',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO VIRGEN DEL ROCÍO',
   },
   {
     label: 'UDENFSM DEL GOBIERNO DE ARAGÓN. SECTOR HUESCA',
     unidad: 'UDENFSM DEL GOBIERNO DE ARAGÓN. SECTOR HUESCA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. GENERAL OBISPO POLANCO',
     unidad: 'UDM SALUD MENTAL DEL H. GENERAL OBISPO POLANCO',
   },
   {
     label: 'UDENFSM DEL GOBIERNO DE ARAGÓN. SECTORES ZARAGOZA I Y III',
     unidad: 'UDENFSM DEL GOBIERNO DE ARAGÓN. SECTORES ZARAGOZA I Y III',
   },
   {
     label:
       'UDM DE SALUD MENTAL DE GRAN CANARIA. C.H. UNIVERSITARIO INSULAR MATERNO-INFANTIL',
     unidad:
       'UDM DE SALUD MENTAL DE GRAN CANARIA. C.H. UNIVERSITARIO INSULAR MATERNO-INFANTIL',
   },
   {
     label: 'UDM SALUD MENTAL DE TENERIFE. H. UNIVERSITARIO DE CANARIAS',
     unidad: 'UDM SALUD MENTAL DE TENERIFE. H. UNIVERSITARIO DE CANARIAS',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO MARQUÉS DE VALDECILLA',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO MARQUÉS DE VALDECILLA',
   },
   {
     label:
       'UDM SALUD MENTAL DEL ÁREA ÚNICA DE GESTIÓN DE ESPECIALIZADA DE ATENCIÓN ALBACETE',
     unidad:
       'UDM SALUD MENTAL DEL ÁREA ÚNICA DE GESTIÓN DE ESPECIALIZADA DE ATENCIÓN ALBACETE',
   },
   {
     label: 'UDM SALUD MENTAL GERENCIA ATENCIÓN INTEGRADA ALCÁZAR DE S. JUAN',
     unidad: 'UDM SALUD MENTAL GERENCIA ATENCIÓN INTEGRADA ALCÁZAR DE S. JUAN',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. GENERAL UNIVERSITARIO DE CIUDAD REAL',
     unidad: 'UDM SALUD MENTAL DEL H. GENERAL UNIVERSITARIO DE CIUDAD REAL',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO DE GUADALAJARA',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO DE GUADALAJARA',
   },
   {
     label: 'UDM SALUD MENTAL H. NTRA. SRA. DEL PRADO DE TALAVERA DE LA REINA',
     unidad: 'UDM SALUD MENTAL H. NTRA. SRA. DEL PRADO DE TALAVERA DE LA REINA',
   },
   {
     label: 'UDM SALUD MENTAL C.H. UNIVERSITARIO DE TOLEDO',
     unidad: 'UDM SALUD MENTAL C.H. UNIVERSITARIO DE TOLEDO',
   },
   {
     label: 'UDM SALUD MENTAL DEL COMPLEJO ASISTENCIAL DE ZAMORA',
     unidad: 'UDM SALUD MENTAL DEL COMPLEJO ASISTENCIAL DE ZAMORA',
   },
   {
     label: 'UDM SALUD MENTAL C. ASISTENCIAL UNIVERSITARIO DE LEÓN',
     unidad: 'UDM SALUD MENTAL C. ASISTENCIAL UNIVERSITARIO DE LEÓN',
   },
   {
     label:
       'UDM SALUD MENTAL DEL COMPLEJO ASISTENCIAL UNIVERSITARIO DE PALENCIA',
     unidad:
       'UDM SALUD MENTAL DEL COMPLEJO ASISTENCIAL UNIVERSITARIO DE PALENCIA',
   },
   {
     label: 'UDM SALUD MENTAL H. EL BIERZO',
     unidad: 'UDM SALUD MENTAL H. EL BIERZO',
   },
   {
     label: 'UDM SALUD MENTAL COMPLEJO ASISTENCIAL UNIVERSITARIO DE SALAMANCA',
     unidad: 'UDM SALUD MENTAL COMPLEJO ASISTENCIAL UNIVERSITARIO DE SALAMANCA',
   },
   {
     label: 'UDM SALUD MENTAL COMPLEJO ASISTENCIAL DE SORIA',
     unidad: 'UDM SALUD MENTAL COMPLEJO ASISTENCIAL DE SORIA',
   },
   {
     label: 'UDENFSM DE VALLADOLID. H. CLÍNICO UNIVERSITARIO',
     unidad: 'UDENFSM DE VALLADOLID. H. CLÍNICO UNIVERSITARIO',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARI GERMANS TRIAS I PUJOL',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARI GERMANS TRIAS I PUJOL',
   },
   {
     label: 'UDENFSM H. CLÍNIC DE BARCELONA',
     unidad: 'UDENFSM H. CLÍNIC DE BARCELONA',
   },
   {
     label: 'UDENFSM H. DEL MAR PARC DE SALUT MAR',
     unidad: 'UDENFSM H. DEL MAR PARC DE SALUT MAR',
   },
   {
     label: 'UDM SALUD MENTAL H. DE LA SANTA CREU I SANT PAU',
     unidad: 'UDM SALUD MENTAL H. DE LA SANTA CREU I SANT PAU',
   },
   {
     label: 'UDENFSM H. UNIVERSITARI DE BELLVITGE',
     unidad: 'UDENFSM H. UNIVERSITARI DE BELLVITGE',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARI ARNAU DE VILANOVA DE LLEIDA',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARI ARNAU DE VILANOVA DE LLEIDA',
   },
   {
     label:
       'UDM SALUD MENTAL ALTHAIA. XARXA ASSISTENCIAL UNIVERSITÀRIA MANRESA',
     unidad:
       'UDM SALUD MENTAL ALTHAIA. XARXA ASSISTENCIAL UNIVERSITÀRIA MANRESA',
   },
   {
     label: 'UDM SALUT MENTAL SAGRAT COR. SERVEIS DE SALUT MENTAL',
     unidad: 'UDM SALUT MENTAL SAGRAT COR. SERVEIS DE SALUT MENTAL',
   },
   {
     label: 'UDM SALUD MENTAL CONSORCI SANITARI DEL MARESME',
     unidad: 'UDM SALUD MENTAL CONSORCI SANITARI DEL MARESME',
   },
   {
     label: 'UDM SALUD MENTAL INSTITUT PERE MATA',
     unidad: 'UDM SALUD MENTAL INSTITUT PERE MATA',
   },
   {
     label: 'UDM SALUD MENTAL CORPORACIÓ SANITÀRIA PARC TAULÍ',
     unidad: 'UDM SALUD MENTAL CORPORACIÓ SANITÀRIA PARC TAULÍ',
   },
   {
     label: 'UDM SALUD MENTAL INSTITUT D´ASSISTÈNCIA SANITÀRIA',
     unidad: 'UDM SALUD MENTAL INSTITUT D´ASSISTÈNCIA SANITÀRIA',
   },
   {
     label: 'UDM SALUD MENTAL BENITO MENNI',
     unidad: 'UDM SALUD MENTAL BENITO MENNI',
   },
   {
     label: 'UDM SALUD MENTAL PARC SANITARI SANT JOAN DE DÉU',
     unidad: 'UDM SALUD MENTAL PARC SANITARI SANT JOAN DE DÉU',
   },
   {
     label: 'UDENFSM H. UNIVERSITARI MÚTUA TERRASSA',
     unidad: 'UDENFSM H. UNIVERSITARI MÚTUA TERRASSA',
   },
   {
     label: 'UDM SALUD MENTAL CONSORCI SANITARI DE TERRASSA',
     unidad: 'UDM SALUD MENTAL CONSORCI SANITARI DE TERRASSA',
   },
   {
     label: 'UDM SALUD MENTAL CONSORCI HOSPITALARI DE VIC',
     unidad: 'UDM SALUD MENTAL CONSORCI HOSPITALARI DE VIC',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO PRINCIPE DE ASTURIAS',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO PRINCIPE DE ASTURIAS',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO FUNDACIÓN ALCORCÓN',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO FUNDACIÓN ALCORCÓN',
   },
   {
     label: 'UDM SALUD MENTAL DEL CENTRO SAN JUAN DE DIOS DE CIEMPOZUELOS',
     unidad: 'UDM SALUD MENTAL DEL CENTRO SAN JUAN DE DIOS DE CIEMPOZUELOS',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO DEL HENARES',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO DEL HENARES',
   },
   {
     label: 'UDM SALUD MENTAL INS. PSIQUIÁTRICO JOSÉ GERMAIN DE LEGANÉS',
     unidad: 'UDM SALUD MENTAL INS. PSIQUIÁTRICO JOSÉ GERMAIN DE LEGANÉS',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO RAMÓN Y CAJAL',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO RAMÓN Y CAJAL',
   },
   {
     label: 'UDM SALUD MENTAL FUNDACIÓN JIMÉNEZ DÍAZ',
     unidad: 'UDM SALUD MENTAL FUNDACIÓN JIMÉNEZ DÍAZ',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO LA PAZ DE MADRID',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO LA PAZ DE MADRID',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO 12 DE OCTUBRE',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO 12 DE OCTUBRE',
   },
   {
     label: 'UDM SALUD MENTAL H. CENTRAL DE LA DEFENSA GOMEZ ULLA',
     unidad: 'UDM SALUD MENTAL H. CENTRAL DE LA DEFENSA GOMEZ ULLA',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO INFANTA LEONOR',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO INFANTA LEONOR',
   },
   {
     label: 'UDM SALUD MENTAL H. DR. RODRIGUEZ LAFORA',
     unidad: 'UDM SALUD MENTAL H. DR. RODRIGUEZ LAFORA',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO GREGORIO MARAÑON',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO GREGORIO MARAÑON',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO CLINICO SAN CARLOS',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO CLINICO SAN CARLOS',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO DE FUENLABRADA',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO DE FUENLABRADA',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO PUERTA DE HIERRO',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO PUERTA DE HIERRO',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO REY JUAN CARLOS',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO REY JUAN CARLOS',
   },
   {
     label: 'UDM SALUD MENTAL H. UNIVERSITARIO INFANTA CRISTINA DE PARLA',
     unidad: 'UDM SALUD MENTAL H. UNIVERSITARIO INFANTA CRISTINA DE PARLA',
   },
   {
     label: 'UDM SALUD MENTAL DE NAVARRA',
     unidad: 'UDM SALUD MENTAL DE NAVARRA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO LA RIBERA',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO LA RIBERA',
   },
   {
     label:
       'UDM SALUD MENTAL DEL CONSORCIO HOSPITALARIO PROVINCIAL DE CASTELLÓN',
     unidad:
       'UDM SALUD MENTAL DEL CONSORCIO HOSPITALARIO PROVINCIAL DE CASTELLÓN',
   },
   {
     label:
       'UDM SALUD MENTAL H. GENERAL UNIVERSITARIO DE ELDA-VIRGEN DE LA SALUD',
     unidad:
       'UDM SALUD MENTAL H. GENERAL UNIVERSITARIO DE ELDA-VIRGEN DE LA SALUD',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO SANT JOAN DE ALICANTE',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO SANT JOAN DE ALICANTE',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARI I POLITÈCNIC LA FE',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARI I POLITÈCNIC LA FE',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. CLÍNICO UNIVERSITARIO DE VALENCIA',
     unidad: 'UDM SALUD MENTAL DEL H. CLÍNICO UNIVERSITARIO DE VALENCIA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO DOCTOR PESET',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO DOCTOR PESET',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. MARINA BAIXA',
     unidad: 'UDM SALUD MENTAL DEL H. MARINA BAIXA',
   },
   {
     label: 'UDM SALUD MENTAL C.H.UNIVERSITARIO DE BADAJOZ',
     unidad: 'UDM SALUD MENTAL C.H.UNIVERSITARIO DE BADAJOZ',
   },
   {
     label: 'UDENFSM DEL ÁREA DE SALUD DE CÁCERES',
     unidad: 'UDENFSM DEL ÁREA DE SALUD DE CÁCERES',
   },
   {
     label: 'UDM SALUD MENTAL DEL C.H. DE MÉRIDA',
     unidad: 'UDM SALUD MENTAL DEL C.H. DE MÉRIDA',
   },
   {
     label: 'UDM SALUD MENTAL DEL ÁREA DE SALUD DE PLASENCIA',
     unidad: 'UDM SALUD MENTAL DEL ÁREA DE SALUD DE PLASENCIA',
   },
   {
     label: 'UDM SALUD MENTAL DEL C.H. UNIVERSITARIO  A CORUÑA',
     unidad: 'UDM SALUD MENTAL DEL C.H. UNIVERSITARIO  A CORUÑA',
   },
   {
     label: 'UDM SALUD MENTAL DEL CH UNIVERSITARIO DE FERROL',
     unidad: 'UDM SALUD MENTAL DEL CH UNIVERSITARIO DE FERROL',
   },
   { label: 'UDM SALUD MENTAL DE LUGO', unidad: 'UDM SALUD MENTAL DE LUGO' },
   {
     label: 'UDM SALUD MENTAL C. H. DE OURENSE',
     unidad: 'UDM SALUD MENTAL C. H. DE OURENSE',
   },
   {
     label: 'UDM SALUD MENTAL DE PONTEVEDRA',
     unidad: 'UDM SALUD MENTAL DE PONTEVEDRA',
   },
   {
     label: 'UDM SALUD MENTAL DE SANTIAGO DE COMPOSTELA',
     unidad: 'UDM SALUD MENTAL DE SANTIAGO DE COMPOSTELA',
   },
   {
     label: 'UDENFSM SERGAS. C.H. UNIVERSITARIO DE VIGO. EOXI VIGO',
     unidad: 'UDENFSM SERGAS. C.H. UNIVERSITARIO DE VIGO. EOXI VIGO',
   },
   {
     label: 'UDM SALUD MENTAL DE LES ILLES BALEARS (H. DE INCA)',
     unidad: 'UDM SALUD MENTAL DE LES ILLES BALEARS (H. DE INCA)',
   },
   {
     label: 'UDM SALUD MENTAL DE LES ILLES BALEARS (H. DE MANACOR)',
     unidad: 'UDM SALUD MENTAL DE LES ILLES BALEARS (H. DE MANACOR)',
   },
   {
     label:
       'UDM SALUD MENTAL DE LES ILLES BALEARS (H. UNIVERSITARIO SON LLÀTZER)',
     unidad:
       'UDM SALUD MENTAL DE LES ILLES BALEARS (H. UNIVERSITARIO SON LLÀTZER)',
   },
   {
     label:
       'UDM SALUD MENTAL DE LES ILLES BALEARS (H.UNIVERSITARIO SON ESPASES)',
     unidad:
       'UDM SALUD MENTAL DE LES ILLES BALEARS (H.UNIVERSITARIO SON ESPASES)',
   },
   {
     label: 'UDM SALUD MENTAL DEL SERVICIO RIOJANO DE SALUD',
     unidad: 'UDM SALUD MENTAL DEL SERVICIO RIOJANO DE SALUD',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO CRUCES',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO CRUCES',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO BASURTO',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO BASURTO',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO DONOSTIA',
     unidad: 'UDM SALUD MENTAL DEL H. UNIVERSITARIO DONOSTIA',
   },
   {
     label: 'UDM SALUD MENTAL DEL H. GALDAKAO-USANSOLO',
     unidad: 'UDM SALUD MENTAL DEL H. GALDAKAO-USANSOLO',
   },
   {
     label: 'UDENFSM-OSAKIDETZA/SERVICIO VASCO DE SALUD. VITORIA/GASTEIZ',
     unidad: 'UDENFSM-OSAKIDETZA/SERVICIO VASCO DE SALUD. VITORIA/GASTEIZ',
   },
   {
     label: 'UDM RED SALUD MENTAL DE BIZKAIA',
     unidad: 'UDM RED SALUD MENTAL DE BIZKAIA',
   },
   {
     label: 'UDM SALUD MENTAL PRINCIPADO ASTURIAS. H. VALLE DEL NALÓN',
     unidad: 'UDM SALUD MENTAL PRINCIPADO ASTURIAS. H. VALLE DEL NALÓN',
   },
   {
     label:
       'DM SALUD MENTAL PRINCIPADO ASTURIAS (H. UNIVERSITARIO SAN AGUSTÍN)',
     unidad:
       'DM SALUD MENTAL PRINCIPADO ASTURIAS (H. UNIVERSITARIO SAN AGUSTÍN)',
   },
   {
     label:
       'UDM SALUD MENTAL PRINCIPADO ASTURIAS (H. UNIVERSITARIO DE CABUEÑES)',
     unidad:
       'UDM SALUD MENTAL PRINCIPADO ASTURIAS (H. UNIVERSITARIO DE CABUEÑES)',
   },
   {
     label:
       'UDM SALUD MENTAL PRINCIPADO DE ASTURIAS (H. UNIVERSITARIO CENTRAL ASTURIAS)',
     unidad:
       'UDM SALUD MENTAL PRINCIPADO DE ASTURIAS (H. UNIVERSITARIO CENTRAL ASTURIAS)',
   },
   {
     label:
       'UDM SALUD MENTAL REGIÓN DE MURCIA C.H. UNIVERSITARIO STA. Mª DEL ROSELL - STA. LUCIA DE CARTAGENA',
     unidad:
       'UDM SALUD MENTAL REGIÓN DE MURCIA C.H. UNIVERSITARIO STA. Mª DEL ROSELL - STA. LUCIA DE CARTAGENA',
   },
   {
     label:
       'UDM SALUD MENTAL REGIÓN DE MURCIA. H. UNIVERSITARIO RAFAEL MÉNDEZ DE LORCA',
     unidad:
       'UDM SALUD MENTAL REGIÓN DE MURCIA. H. UNIVERSITARIO RAFAEL MÉNDEZ DE LORCA',
   },
   {
     label:
       'UDM SALUD MENTAL REGIÓN DE MURCIA. H. GENERAL UNIVERSITARIO J.Mª MORALES MESEGUER DE MURCIA',
     unidad:
       'UDM SALUD MENTAL REGIÓN DE MURCIA. H. GENERAL UNIVERSITARIO J.Mª MORALES MESEGUER DE MURCIA',
   },
   {
     label:
       'UDM SALUD MENTAL REGIÓN DE MURCIA. H. GENERAL UNIVERSITARIO REINA SOFÍA DE MURCIA',
     unidad:
       'UDM SALUD MENTAL REGIÓN DE MURCIA. H. GENERAL UNIVERSITARIO REINA SOFÍA DE MURCIA',
   },
 ];
