import { useState } from 'react';
import { RotatorioAndEvaluacionRepository } from '../../../Repositories/rotatorioAndEvaluacionRepository';
import { TestWithReview } from '../../../Models/Test';
import { TestBodyRow } from './TestBodyRow';
import { TestTableFilter } from './TestFilter';
import { TestHeadRow } from './TestHeadRow';
import {
  OnUpdateTable,
  PaginatedTable,
} from '../../../Components/PaginatedTable/paginatedTable';

const rotatorioAndEvaluacionRepository = new RotatorioAndEvaluacionRepository();

export const TestTable: React.FC<{ id: string }> = (props) => {
  const [reviews, setReviews] = useState<TestWithReview[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const onUpdateQuery: OnUpdateTable = ({ page, rowsPerPage, filter }) => {
    setLoading(true);

    rotatorioAndEvaluacionRepository
      .getAll({
        page,
        size: rowsPerPage,
        filter: { ...filter, studentId: props.id },
      })
      .then((response) => {
        setReviews(response.data);
        setCount(response.count);
        setLoading(false);
      });
  };

  console.log('loading');

  return (
    <>
      <PaginatedTable
        title="Mis Evaluaciones"
        filterRow={TestTableFilter}
        loading={loading}
        onUpdate={onUpdateQuery}
        headRow={TestHeadRow}
        bodyRow={TestBodyRow}
        count={count}
        data={reviews}
      ></PaginatedTable>
    </>
  );
};
