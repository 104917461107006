import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {
  StartTest,
  TestRotatorio,
  TestEvaluacionAnual,
} from './Containers/Tests';
import { Home } from './Containers/Home';
import { Login } from './Containers/Login';
import { UserProvider } from './Providers/UserProvider';
import { AdminRoute, PrivateRoute } from './Components/PrivateRoute';
import { ListUsers } from './Containers/Admin/Users/List';
import { CreateUser, EditUser } from './Containers/Admin/Users/User';
import { SignIn } from './Containers/SignIn';
import { StaticPage } from './Components/Static';
import { StringValues } from './Strings';
import { Contact } from './Containers/Contact';
import { Collaborators } from './Containers/Collaborators';
import { Profile } from './Containers/Profile/Profile';
import { CreateStudent } from './Containers/Students/Students';
import {
  ReviewStart,
  StudentTestList,
  ReviewTest,
  ReviewYear,
} from './Containers/Review';
import { TeacherHome } from './Containers/Home/teacher';
import { NotFoundPage } from './Containers/NotFound';
import { URLS } from './Urls';
import { Admin } from './Containers/Admin';
import { Markdown } from './Containers/Admin/Markdown';
import { AdminDataTests } from './Containers/Admin/Data/index';
import { defaultTheme } from './Components/Themes';
import { ThemeProvider } from '@mui/material';
import { Tutorial } from './Containers/Tutorial';

function App() {
  return (
    <div className="App">
      <UserProvider>
        <ThemeProvider theme={defaultTheme}>
          <BrowserRouter>
            <Routes>
              <Route path={URLS.home} element={<Home />}></Route>
              <Route path={URLS.login} element={<Login />} />
              <Route path={URLS.contact} element={<Contact />} />
              <Route path={URLS.tutorial} element={<Tutorial />} />

              <Route path={URLS.documentation} element={<StaticPage
                    title={StringValues.DocumentaciónTitle}
                    body={StringValues.DocumentaciónText}
                  />} />
              <Route path={URLS.collaborators} element={<Collaborators />} />
              <Route path={URLS.signin} element={<SignIn />}></Route>
              <Route path="/" element={<PrivateRoute />}>
                <Route path={URLS.profile} element={<Profile />}></Route>
                <Route path={URLS.testStart} element={<StartTest />}></Route>
                <Route
                  path={URLS.testRotatorio}
                  element={<TestRotatorio />}
                ></Route>
                <Route
                  path={URLS.testEvaluacion}
                  element={<TestEvaluacionAnual />}
                ></Route>

                <Route path={URLS.teacherHome} element={<TeacherHome />} />
                <Route path={URLS.students} element={<CreateStudent />} />
                <Route path={URLS.reviewStart} element={<ReviewStart />} />
                <Route
                  path={`${URLS.reviewStudent}/:studentId`}
                  element={<StudentTestList />}
                />
                <Route
                  path={`${URLS.reviewStart}/:testType/:reviewId`}
                  element={<ReviewTest />}
                />
                <Route
                  path={`${URLS.reviewYear}/:academicYear/:studentId`}
                  element={<ReviewYear />}
                />
              </Route>

              <Route path="/admin" element={<AdminRoute />}>
                <Route path="" element={<Admin />} />
                <Route path="markdown" element={<Markdown />} />
                <Route path="users" element={<ListUsers />} />
                <Route path="users/:email" element={<EditUser />} />
                <Route path="users/new" element={<CreateUser />} />
                <Route path="data/tests" element={<AdminDataTests />} />
              </Route>

              <Route
                path="informacion"
                element={
                  <StaticPage
                    title={StringValues.InfoTitle}
                    body={StringValues.InfoBody}
                  />
                }
              ></Route>
              <Route
                path="historia"
                element={
                  <StaticPage
                    title={StringValues.HistoriaTitle}
                    body={StringValues.HistoriaBody}
                  />
                }
              ></Route>
              <Route
                path="escala"
                element={
                  <StaticPage
                    title={StringValues.EscalaTitle}
                    body={StringValues.EscalaBody}
                  />
                }
              ></Route>
              <Route
                path="situacion"
                element={
                  <StaticPage
                    title={StringValues.SituacionTitle}
                    body={StringValues.SituacionBody}
                  />
                }
              ></Route>
              <Route
                path="actualidad"
                element={
                  <StaticPage
                    title={StringValues.SituacionTitle}
                    body={StringValues.SituacionBody}
                  />
                }
              ></Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </UserProvider>
    </div>
  );
}

export default App;
