export type User = {
    id?: string,
    password?: string,
    email: string,
    name: string,
    surname: string,
    role: string,
}

export enum Roles {
    Admin = 'ADMIN',
    Teacher = 'TEACHER'
}