import { FC, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
} from '@mui/material';
import { TestFilters } from '../../../Repositories/rotatorioAndEvaluacionRepository';

export type TableFilterProps<T> = {
  onUpdate: (filters: T) => void;
};

export const TestTableFilter: FC<TableFilterProps<TestFilters>> = (props) => {
  const { onUpdate } = props;
  const [filter, setFilter] = useState<TestFilters>({});
  useEffect(() => {
    const updatedFilter: any = { ...filter };
    Object.entries(updatedFilter).forEach(([key, value]) => {
      if (value === undefined || value === null || value === 'All') {
        delete updatedFilter[key];
      }
    });
    onUpdate(updatedFilter);
  }, [filter]);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <FormControl>
        <InputLabel id="test-select-label">Tipo de Formulario</InputLabel>
        <Select
          style={{ minWidth: '200px' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter.testType || 'All'}
          label="Tipo de Formulario"
          onChange={(e) => {
            console.log('eco', e);
            setFilter({ ...filter, testType: e.target.value });
          }}
        >
          <MenuItem value="All">-</MenuItem>
          <MenuItem value="Rotatorio">Rotatorio</MenuItem>
          <MenuItem value="EvaluacionAnual">Evaluacion Anual</MenuItem>
        </Select>
      </FormControl>
    </Toolbar>
  );
};
