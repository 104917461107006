import { Paper, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { View } from '../View';
import remarkGfm from 'remark-gfm';

import styles from './styles.module.css';

type StaticProps = {
  title: string;
  body: string;
};

export const StaticPageContent = (props: StaticProps) => {
  return (
    <Paper className={styles.paper} variant="outlined">
      <Typography
        gutterBottom
        variant="h4"
        component="span"
        className={styles.title}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{props.title}</ReactMarkdown>
      </Typography>
      <Typography
        gutterBottom
        variant="body1"
        component="span"
        className={styles.text}
        style={{ textAlign: 'left' }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{props.body}</ReactMarkdown>
      </Typography>
    </Paper>
  );
};

export const StaticPage: React.FC<StaticProps> = ({ title, body }) => {
  return (
    <View>
      <StaticPageContent title={title} body={body} />
    </View>
  );
};
