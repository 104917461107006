import { Skeleton, TableCell, TableRow } from '@mui/material';

export function TestBodyLoadingRow() {
  return (
    <TableRow
      style={{ border: '0' }}
      key="laodingtesttable"
      sx={{ '& th, & td': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Skeleton variant="rectangular" height={20} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Skeleton variant="rectangular" height={20} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Skeleton variant="rectangular" height={20} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Skeleton variant="rectangular" height={20} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Skeleton variant="rectangular" height={20} />
      </TableCell>
      <TableCell component="th" scope="row">
        <Skeleton variant="rectangular" height={20} />
      </TableCell>
    </TableRow>
  );
}
