import React, { FC } from "react";
import {
  Paper,
  Typography,
  Stack,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Chip,
} from "@mui/material";
import { View } from "../../Components/View";
import RubricaQuestions from "../../RubricaQuestions.json";
import styles from "./styles.module.css";
import { useParams } from "react-router-dom";
import { YearReview } from "../../Models/Test";
import { Student } from "../../Models/Student";
import { StudentRepository } from "../../Repositories/StudentRepository";
import { LoadingPage } from "../../Components/Loading/LoadingPage";
import { EvaluacionFinalRepository } from "../../Repositories/EvaluacionFinalRepository";

const evaluacionFinalRepository = new EvaluacionFinalRepository();
const studentRepository = new StudentRepository();

export const ReviewYear: FC = () => {
  const params = useParams();
  const [ready, setReady] = React.useState(false);
  const [evaluation, setEvaluation] = React.useState<null | YearReview>(null);
  const [student, setStudent] = React.useState<null | Student>(null);

  const { academicYear, studentId } = params;

  React.useEffect(() => {
    if (!studentId) return;
    evaluacionFinalRepository
      .getYearReview(studentId)
      .then((reviewResponse) => {
        setEvaluation(reviewResponse.data);
      })
      .then(() => {
        return studentRepository.getById(studentId);
      })
      .then((studentResponse) => setStudent(studentResponse.data))
      .then(() => setReady(true));
  }, [params]);

  if (!ready) return <LoadingPage />;
  if (!student || !evaluation || !academicYear)
    return <div>Error en la descarga de datos</div>;
  return (
    <ReviewRender
      review={evaluation}
      student={student}
      academicYear={academicYear.replace("-", "/")}
      ucs={RubricaQuestions.tutores}
    />
  );
};

export const ReviewRender: React.FC<{
  review: YearReview;
  student: Student;
  academicYear: string;
  ucs: { id: string; title: string; rows: any[] }[];
}> = (props) => {
  return (
    <View>
      <Paper className={styles.container} variant="outlined">
        <Stack spacing={2}>
          <Typography
            gutterBottom
            variant="h5"
            component="span"
            style={{ textAlign: "left" }}
          >
            RESULTADOS DE EVALUACIÓN CURSO ACADÉMICO {props.academicYear}
          </Typography>
          <Typography
            gutterBottom
            variant="body1"
            component="span"
            style={{ textAlign: "left" }}
          >
            Aplicación de la Escala de Evaluación de Competencias Profesionales
            de los Residentes de Enfermería de Salud Mental (ECOEnf-SM)
          </Typography>

          <TableContainer>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="span"
              style={{ textAlign: "left", paddingBottom: "10px" }}
            >
              DATOS DEL RESIDENTE:
            </Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <b>Nombre Residente:</b> {props.student.name}{" "}
                    {props.student.surname}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <b>Unidad Docente Multidisciplinar:</b> U.D.M del Hospital
                    {props.student.school}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <b>Año de residencia:</b> {props.student.schoolYear}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="span"
              style={{ textAlign: "left", paddingBottom: "10px" }}
            >
              Evaluacion por unidades de competencia
            </Typography>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Unidad de Competencia</TableCell>
                  <TableCell>Nota</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow key={1}>
                  <TableCell component="th" scope="row">
                    Valoración y diagnostico
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.allAvg.uc1 * 100) / 100}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell component="th" scope="row">
                    Planificación:
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.allAvg.uc2 * 100) / 100}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell component="th" scope="row">
                    Intervención:
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.allAvg.uc3 * 100) / 100}
                  </TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell component="th" scope="row">
                    Evaluación:
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.allAvg.uc4 * 100) / 100}
                  </TableCell>
                </TableRow>
                <TableRow key={5}>
                  <TableCell component="th" scope="row">
                    Investigación
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.allAvg.uc5 * 100) / 100}
                  </TableCell>
                </TableRow>
                <TableRow key={6}>
                  <TableCell component="th" scope="row">
                    Gestión
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.allAvg.uc6 * 100) / 100}
                  </TableCell>
                </TableRow>
                <TableRow key={7}>
                  <TableCell component="th" scope="row">
                    Docencia
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.allAvg.uc7 * 100) / 100}
                  </TableCell>
                </TableRow>
                <TableRow key={8}>
                  <TableCell component="th" scope="row">
                    Comunicación-Relación Interpersonal:
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.allAvg.uc8 * 100) / 100}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table style={{ marginTop: "20px" }}>
              <TableBody>
                <TableRow key={7}>
                  <TableCell component="th" scope="row">
                    Nota de Unidades de Competencia Específicas (70%):
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.uce * 100) / 100}
                  </TableCell>
                </TableRow>
                <TableRow key={8}>
                  <TableCell component="th" scope="row">
                    Nota de Unidades de Competencia Transversal (30%):
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {Math.round(props.review.uct * 100) / 100}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="subtitle1">NOTA FINAL</Typography>
                      <Chip
                        variant="outlined"
                        color={props.review.pass ? "success" : "error"}
                        label={props.review.pass ? "APTO" : "NO APTO"}
                      />
                      {!props.review.pass && (
                        <Typography
                          gutterBottom
                          variant="caption"
                          component="span"
                          style={{ textAlign: "left" }}
                        >
                          Existen unidades de competencia suspensas por no
                          alcanzar: la nota mínima (5/10), el número mínimo de
                          criterios de evaluación o por criterios
                          imprescindibles no superados. Se recomienda mejorar en
                          esas temáticas. El cálculo de la nota media prevalece
                          sobre la calificación de NO APTO y siempre será
                          decisión final la del tutor/a.
                        </Typography>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Paper>
    </View>
  );
};
