import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Roles, User } from '../../Models/User';
import { UserRepository } from '../../Repositories/UserRepository';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../Urls';

type ProfileProps = {
    user: User,
    setUser: (user: any) => void,
    anchorElUser: any,
    handleOpenUserMenu: (event: any) => void,
    handleCloseUserMenu: () => void,
    handleCloseNavMenu: () => void
}

const userRespository = new UserRepository()

export const Profile: React.FC<ProfileProps> = (props) => {

    const navigate = useNavigate()

    const onLogOut = () => {
        props.setUser(null)
        userRespository.logout().then()
        navigate('/login')
    }

    const onManageUsers = () => {
      navigate('/admin');
    };

    return (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={props.handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar
              alt={props.user.name.toUpperCase()}
              src="/static/images/avatar/2.jpg"
            />
          </IconButton>
        </Tooltip>
        <Menu
          variant="selectedMenu"
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={props.anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(props.anchorElUser)}
          onClose={props.handleCloseUserMenu}
        >
          <MenuItem key="Logout" onClick={onLogOut}>
            <Typography textAlign="center">Logout</Typography>
          </MenuItem>
          <MenuItem key="Profile" onClick={() => navigate(URLS.profile)}>
            <Typography textAlign="center">Perfil</Typography>
          </MenuItem>
          {props.user.role === Roles.Admin && (
            <MenuItem key="Admin Panel" onClick={onManageUsers}>
              <Typography textAlign="center">ADMIN PANEL</Typography>
            </MenuItem>
          )}
        </Menu>
      </Box>
    );
}