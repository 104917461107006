import { ApiDomain } from '../Urls';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export type Response<T> = {
  succes: boolean;
  data: T;
  count: number;
  response: any;
};

export abstract class ApiRepository {
  protected headers = DEFAULT_HEADERS;
  protected defaultRequestInit: RequestInit = {
    headers: this.headers,
    credentials: 'include',
  };

  protected isInternal() {
    return typeof window === 'undefined';
  }

  private getDomain() {
    if (process.env.NODE_ENV === 'production') {
      return '//' + window.location.hostname + '/api';
    }
    return '//' + (process.env.REACT_APP_API_DOMAIN || ApiDomain);
  }

  protected getUrl() {
    return '//' + this.getDomain();
  }

  protected async request<T>(
    url: string,
    requestInit: RequestInit
  ): Promise<Response<T>> {
    const response = await fetch(url, {
      ...this.defaultRequestInit,
      ...requestInit,
    });

    const jsonResponse = await response.json();
    const isPaginated = jsonResponse.count != null;
    const data: T = isPaginated ? jsonResponse.data : jsonResponse;
    const count = isPaginated ? jsonResponse.count : null;
    const succes = response.ok && response.status === 200;
    return { succes, data, response, count };
  }
}
