import { Chip, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { SchoolYear, Student } from "../../../Models/Student";
import { URLS } from "../../../Urls";
import { BodyRowProps } from "../paginatedTable";

export const StudentBodyRow: BodyRowProps<Student> = ({ data: student }) => {
  return (
    <TableRow
      key={student.schoolYear}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Link to={`${URLS.reviewStudent}/${student.id}`}>
          {[student.name, student.surname].join(" ")}
        </Link>
      </TableCell>
      <TableCell component="th" scope="row">
        {student.gender}
      </TableCell>
      <TableCell align="left">
        {student.createdAt && new Date(student.createdAt).toLocaleDateString()}
      </TableCell>
      <TableCell align="left">
        {student.birthday && new Date(student.birthday).toLocaleDateString()}
      </TableCell>
      <TableCell align="left">
        {" "}
        <Chip
          label={student.schoolYear === SchoolYear.Eir1 ? "EIR 1" : "EIR 2"}
          color={
            student.schoolYear === SchoolYear.Eir1 ? "primary" : "secondary"
          }
          variant="filled"
        />
      </TableCell>
    </TableRow>
  );
};
