import { Box, Stack, Typography } from '@mui/material';
import { View } from '../../Components/View';
import { StringValues } from '../../Strings';
import logo from './ecoenf.png';
import styles from './styles.module.css';

export const NotFoundPage = () => {
  return (
    <View>
      <Stack spacing={3} alignItems="center">
        <Box sx={{ width: '100%' }}>
          <Stack spacing={3} className={styles.stack} alignItems="center">
            <img className={styles.image} src={logo} alt="Logo" width="50%" />
            <Typography
              gutterBottom
              variant="h3"
              component="span"
              className={styles.text}
              style={{ textAlign: 'left' }}
            >
              {StringValues.PageNotFoundText}
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </View>
  );
};
