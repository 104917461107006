import {
  Autocomplete,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './start.styles.module.css';
import { View } from '../../Components/View';
import { StudentRepository } from '../../Repositories/StudentRepository';
import { LoadingPage } from '../../Components/Loading/LoadingPage';
import { Student } from '../../Models/Student';
import { URLS } from '../../Urls';
import { StringValues } from '../../Strings';
import ReactMarkdown from 'react-markdown';

const studentRepository = new StudentRepository();

export const ReviewStart: React.FC = (props) => {
  const navigate = useNavigate();

  const [students, setStudents] = React.useState<Student[] | null>(null);
  const [studentId, setStudentId] = React.useState<string | null>(null);

  useEffect(() => {
    studentRepository.getAll().then((s) => {
      setStudents(s.data);
    });
  }, [studentId]);

  if (!students) return <LoadingPage />;

  const studentOptions = students.map((s) => {
    return { label: s.name + ' ' + s.surname, value: s.id };
  });
  const studentOption = students
    .filter((s) => s.id === studentId)
    .map((s) => ({ label: s.name + ' ' + s.surname, value: s.id }))[0];

  const canPass = !!studentId;

  const onFinish = () => {
    if (!canPass) return;
    navigate(`${URLS.reviewStudent}/${studentId}`);
  };

  return (
    <View>
      <Paper className={styles.contenedor} variant="outlined">
        <Typography
          gutterBottom
          variant="h5"
          component="span"
          style={{ textAlign: 'left' }}
        >
          {StringValues.EvaluacionStartTitle}
        </Typography>

        <Typography
          gutterBottom
          variant="body1"
          component="span"
          className={styles.text}
          style={{ textAlign: 'left' }}
        >
          <ReactMarkdown>{StringValues.EvaluacionStartBody}</ReactMarkdown>
        </Typography>

        <Stack
          spacing={{ xs: 2, sm: 4, md: 6, lg: 8, xl: 10 }}
          alignItems="left"
          justifyContent="left"
          direction={{ xs: 'column', sm: 'row' }}
          className={styles.buttonContainer}
        >
          <Autocomplete
            disablePortal
            value={studentOption}
            options={studentOptions}
            onChange={(e, value) => setStudentId(value?.value ?? '')}
            renderInput={(params) => (
              <TextField {...params} label="Introduzca Alumno" />
            )}
          />

          <Button disabled={!canPass} onClick={onFinish}>
            Comenzar
          </Button>
        </Stack>
      </Paper>
    </View>
  );
};
